import React from 'react'
import { Button, useMediaQuery, useTheme } from '@mui/material'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Background from '../assets/startup-homepage-hero-bg.webp'
import SingularityStartupPromo from '../components/SingularityStartup/SingularityStartupPromo'
import SingularityStartupTiers from '../components/SingularityStartup/SingularityStartupTiers'
import SingularityStartupCalculator from '../components/SingularityStartup/SingularityStartupCalculator'
import SingularityStartupPricingView from '../components/SingularityStartup/SingularityStartupPricingView'
import ContactUs from '../components/Home/ContactUs'
import Icons from '../components/Home/Icons'

const SingularityStartupPricing: React.FC = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  if (isMobile) {
    return (
      <>
        <SingularityStartupTiers />
        <SingularityStartupPricingView />
        <SingularityStartupCalculator />
        <div style={{ marginTop: '2rem' }}></div>
        <SingularityStartupPromo />
        <div style={{ marginTop: '4rem' }}></div>
        <ContactUs />
        {isMobile ? <div></div> : <Icons />}
      </>
    )
  }

  return (
    <>
      <SingularityStartupTiers />
      <SingularityStartupPricingView />
      <SingularityStartupCalculator />
      <div style={{ marginTop: '2rem' }}></div>
      <SingularityStartupPromo />
      <div style={{ marginTop: '40rem' }}></div>
      <ContactUs />
      {isMobile ? <div></div> : <Icons />}
    </>
  )
}

export default SingularityStartupPricing
