import React, { useState, useEffect } from 'react'
import {
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { useNavigate, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const pages = [
  { name: 'Home', path: '/' },
  { name: 'Our Work', path: '/our-work' },
  { name: 'About us', path: '/about' },
  { name: 'Services', path: '/services' },
  { name: 'Contact', path: '/contact' },
  { name: 'Singularity Startup', path: '/singularity-startup' },
]

const agency_pages = [
  { name: 'Pricelist', path: '/singularity-startup/pricing' },
  { name: 'Process', path: '/singularity-startup/process' },
  { name: 'Singularity Agency', path: '/' },
]

const DrawerComp: React.FC = () => {
  const { t } = useTranslation()
  const [openDrawer, setOpenDrawer] = useState(false)
  const [deviceWidth, setDeviceWidth] = useState(window.innerWidth)
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const handleResize = () => {
      setDeviceWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const handleLinkClick = (path: string) => {
    navigate(path)
    setOpenDrawer(false)
  }

  const listToShow = location.pathname.startsWith('/singularity-startup')
    ? agency_pages
    : pages

  return (
    <React.Fragment>
      <Drawer
        variant="temporary"
        anchor="right"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        PaperProps={{
          sx: {
            backgroundColor: '#0F0F0F',
            width: deviceWidth / 1.7,
          },
        }}
      >
        <List>
          {listToShow.map((page, index) => (
            <ListItemButton
              onClick={() => handleLinkClick(page.path)}
              key={index}
            >
              <ListItemIcon style={{ padding: '.5rem' }}>
                {page.name !== 'Singularity Startup' &&
                page.name !== 'Singularity Agency' ? (
                  <ListItemText style={{ color: 'white' }}>
                    {page.name}
                  </ListItemText>
                ) : (
                  <ListItemText
                    style={{
                      color: 'white',
                      padding: '12px',
                      fontSize: '6px',
                      background: '#0076FF',
                    }}
                  >
                    {page.name}
                  </ListItemText>
                )}
              </ListItemIcon>
            </ListItemButton>
          ))}
        </List>
      </Drawer>
      <IconButton
        sx={{ color: 'white', marginLeft: 'auto' }}
        onClick={() => setOpenDrawer(!openDrawer)}
      >
        <MenuIcon />
      </IconButton>
    </React.Fragment>
  )
}

export default DrawerComp
