import React from 'react'
import Grid from '@mui/material/Grid'
import '../../styles/About/Singularity.css'
import { useTranslation } from 'react-i18next'
import { useTheme, useMediaQuery } from '@mui/material'

const Singularity: React.FC = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  if (isMobile) {
    return (
      <>
        <Grid container className={'singularity_container'}>
          <Grid item xs={12} className={'singularity_grid'}>
            <h2 className={'singularity_heading'}>
              {t('about_screen_singularity_heading')}
            </h2>
            <div className={'singularity_divider'}></div>
            <div className={'singularity_text'}>
              {t('about_screen_singularity_text')}
            </div>
          </Grid>
        </Grid>
      </>
    )
  }

  return (
    <>
      <Grid container className={'singularity_container'}>
        <Grid item xs={12} className={'singularity_grid'}>
          <h2 className={'singularity_heading'}>
            {t('about_screen_singularity_heading')}
          </h2>
          <div className={'singularity_divider'}></div>
          <div className={'singularity_text'}>
            {t('about_screen_singularity_text')}
          </div>
        </Grid>
      </Grid>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div
          style={{
            width: '90%',
            height: '1px',
            backgroundColor: '#FFF',
            opacity: '0.24',
          }}
        ></div>
      </div>
    </>
  )
}

export default Singularity
