import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'

const NotFound: React.FC = () => {
  const router = useNavigate()

  useEffect(() => {
    router('/')
  }, [])

  return (
    <Box
      textAlign="center"
      color={'white'}
      style={{
        height: '78vh',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography variant="h4" gutterBottom>
        Oops! Page not found
      </Typography>
      <Typography variant="body1" gutterBottom>
        The page you are looking for might have been removed, had its name
        changed, or is temporarily unavailable.
      </Typography>
      <Link to="/" style={{ textDecoration: 'none' }}>
        <Button variant="contained" color="primary">
          Go to Home
        </Button>
      </Link>
    </Box>
  )
}

export default NotFound
