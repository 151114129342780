// ICONS
import DesignIcon from '../../assets/design-icon.png'
import DatabaseIcon from '../../assets/database-icon.png'
import ArchitectureIcon from '../../assets/architecture-icon.png'

// IMAGES
import StartupBoostYourProductivity from '../../assets/startup-boost-your-productivity.png'
import StartupSaveCostDeliverFaster from '../../assets/startup-save-cost-deliver-faster.png'
import StartupTogetherFaster from '../../assets/startup-together-faster.png'

export interface Data {
  id: number
  heading: string
  text: string
  image: string
}

export const SingularityData: Data[] = [
  {
    id: 1,
    heading: 'Deploy apps \n rapidly',
    text: 'Harness the power of Singularity tool for \n accelerated app deployment, reducing time-to-\nmarket significantly.',
    image: `${StartupTogetherFaster}`,
  },
  {
    id: 2,
    heading: 'Save cost \n deliver faster',
    text: 'Utilize our AI-powered development with \n Singularity to efficiently manage resources, \n curbing expenses, and achieve swifter project \n delivery.',
    image: `${StartupSaveCostDeliverFaster}`,
  },
  {
    id: 3,
    heading: 'Boost your \n productivity',
    text: 'Experience a remarkable increase in your \n productivity through optimized processes and \n enhancing features.',
    image: `${StartupBoostYourProductivity}`,
  },
]

export const SingularityCards: Data[] = [
  {
    id: 1,
    heading: 'Design',
    text: "With an easy wireframing and drag-and-\ndrop editor, you can create a front-end \n that complements the AI's code in just a \n few minutes.",
    image: `${DesignIcon}`,
  },
  {
    id: 2,
    heading: 'Database',
    text: 'Choose the database of your preference \n directly within the system. After deploying \n your app, you will have direct access to \n your cloud space.',
    image: `${DatabaseIcon}`,
  },
  {
    id: 3,
    heading: 'Architecture',
    text: "You can rely on Singularity's architectural \n approach, and also customize it to your \n preferences. Enhance the app's \n architecture, tweak it, or completely \n change it to suit your needs.",
    image: `${ArchitectureIcon}`,
  },
]
