// ICONS
import SoftwareDevIcon from '../../assets/software-dev-icon.png'
import ApplicationRefactoringIcon from '../../assets/aplication-refactoring-icon.png'
import ArchitectureReviewIcon from '../../assets/architecture-review-icon.png'
import SoftwareMaintenanceIcon from '../../assets/software-maintenance-icon.png'
import ConsultingIcon from '../../assets/consulting-icon.png'

// BACKGROUNDS
import ServicesApplicationRefactoring from '../../assets/services-application-refactoring.png'
import ServicesArchitectureReview from '../../assets/services-architecture-review.png'
import ServicesConsulting from '../../assets/services-consulting.png'
import ServicesSoftwareDev from '../../assets/services-software-dev.png'
import ServicesSoftwareMaintenance from '../../assets/services-software-maintenance.png'

export interface MobileService {
  id: number
  heading: string
  text: string
  icon: string
  background: string
}

export const MobileServiceData: MobileService[] = [
  {
    id: 1,
    heading: 'Software development',
    text: 'From the initial brief, and analysis of the current state, through design, and development, to deployment and long-term maintenance - we provide a complete life cycle of customized application development. Thanks to many years of experience in various sectors, we are rarely surprised by a task.',
    icon: `${SoftwareDevIcon}`,
    background: `${ServicesSoftwareDev}`,
  },
  {
    id: 2,
    heading: 'Application refactoring',
    text: 'While old apps may still hold value, without proper maintenance over time, they can lose compatibility. Application refactoring is a way to breathe new life into these applications. We will preserve their original functionality and interface, while also ensuring a seamless transition to cloud platforms that align with current security standards.',
    icon: `${ApplicationRefactoringIcon}`,
    background: `${ServicesApplicationRefactoring}`,
  },
  {
    id: 3,
    heading: 'Architecture review',
    text: "When a developer works on a project for an extended period, objectivity can decline. The solution is an architecture review. We will assess the frameworks used, the code structure, and their impact on the application's functionality, performance, or speed, and then provide recommendations for improvements.",
    icon: `${ArchitectureReviewIcon}`,
    background: `${ServicesArchitectureReview}`,
  },
  {
    id: 4,
    heading: 'Software maintenance',
    text: "Applications require ongoing maintenance. This can be covered either through long-term collaboration, where we oversee the software we've developed, or by providing maintenance for applications that you do not have internal resources to manage.",
    icon: `${SoftwareMaintenanceIcon}`,
    background: `${ServicesSoftwareMaintenance}`,
  },
  {
    id: 5,
    heading: 'Consulting',
    text: 'Our services encompass consultations as well. Consulting is included in the service price when you opt for custom development, refactoring, or architecture review. However, certain cases may demand separate consulting, distinct from code development. And who better to offer guidance than our experienced team of programmers?',
    icon: `${ConsultingIcon}`,
    background: `${ServicesConsulting}`,
  },
]
