import React from 'react'
import Grid from '@mui/material/Grid'
import '../../styles/About/Team.css'
import { useTranslation } from 'react-i18next'
import { useTheme, useMediaQuery } from '@mui/material'

const Team: React.FC = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const skills = [
    {
      id: 1,
      name: 'Jaroslav Socháň',
      experience: '10 YEARS OF APPLICATION DEVELOPMENT EXPERIENCE',
      work: 'Architect, Team Leader, DevOps Engineer, Backend Engineer',
      skills: [
        'ARCHIMATE',
        'JAVA',
        'SPRING',
        'HIBERNATE',
        'POSTGRESQL',
        'CI/CD',
        'GITHUB ACTIONS',
      ],
      link: 'https://www.linkedin.com/in/jaroslav-soch%C3%A1%C5%88-5355b610a/',
    },
    {
      id: 2,
      name: 'Jozef Fuker',
      experience: '15 YEARS OF APPLICATION DEVELOPMENT AND DEVOPS EXPERIENCE',
      work: 'DevOps Engineer, Cloud Engineer, Security Engineer',
      skills: [
        'KUBERNETES',
        'DOCKER',
        'RANCHER',
        'AWS',
        'AZURE',
        'CI/CD',
        'GITHUB ACTIONS',
      ],
      link: 'https://www.linkedin.com/in/jozef-fuker-642b3883/',
    },
    {
      id: 3,
      name: 'Pavol Součík',
      experience: '6 YEARS OF EXPERIENCE IN FRONTEND DEVELOPMENT',
      work: 'Frontend Developer',
      skills: ['TYPESCRIPT', 'ANGULAR', 'JAVASCRIPT', 'REACT'],
      link: 'https://www.linkedin.com/in/pavol-%C5%A1ou%C4%8D%C3%ADk-075068124/',
    },
    {
      id: 4,
      name: 'Darius Gal',
      experience: '15 YEARS OF APPLICATION DEVELOPMENT EXPERIENCE',
      work: 'Architect, Fullstack Developer',
      skills: [
        'C#',
        'JAVA',
        'TYPESCRIPT',
        'GITHUB ACTIONS',
        'SPRING',
        'HIBERNATE',
        'POSTGRESQL',
      ],
      link: 'https://www.linkedin.com/in/darius-gal-16a41022/',
    },
    {
      id: 5,
      name: 'Roman Majerský',
      experience: '15 YEARS OF EXPERIENCE IN CONTENT MARKETING',
      work: 'Marketing Strategist, Content Specialist, Copywriter',
      link: 'https://www.linkedin.com/in/romanmajersky/',
    },
    {
      id: 6,
      name: 'GoodFridays',
      experience: '15 YEARS OF APPLICATION DEVELOPMENT EXPERIENCE',
      work: 'External Design Studio',
      link: '',
    },
  ]

  const handleLinkClick = (link: string) => {
    const url = link
    window.open(url, '_blank')
  }

  if (isMobile) {
    return (
      <>
        <Grid container className={'team_container'}>
          <Grid item xs={12} className={'team_grid'}>
            <h2 className={'team_heading'}>
              {t('about_screen_team_heading')}
            </h2>
            <div className={'team_divider'}></div>
          </Grid>
          <Grid item>
            {skills.map((skill) => (
              <Grid item key={skill.id} xs={12} sx={{ marginTop: '1.5rem' }}>
                <div className={'team_loop_container'}>
                  <div className={'team_loop_container_div'}>
                    <div className={'team_loop_container_heading'}>
                      {skill.name}
                    </div>
                    <div className={'team_loop_container_subheading'}>
                      {skill.experience}
                    </div>
                    <div className={'team_loop_container_text'}>
                      {skill.work}
                    </div>
                    <div className={'team_loop_container_skills'}>
                      {skill.skills?.map((individualSkill) => (
                        <div
                          key={individualSkill}
                          className={'team_loop_container_skills_text'}
                        >
                          {individualSkill}
                        </div>
                      ))}
                    </div>
                  </div>
                  {skill.link !== '' ? (
                    <div
                      onClick={() => handleLinkClick(skill.link)}
                      style={{
                        color: '#0076FF',
                        fontSize: '20px',
                        fontStyle: 'normal',
                        fontWeight: 700,
                        lineHeight: 'normal',
                        textAlign: 'right',
                        cursor: 'pointer',
                      }}
                    >
                      in
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </>
    )
  }

  return (
    <>
      <Grid container className={'team_container'}>
        <Grid item xs={12} className={'team_grid'}>
          <h2 className={'team_heading'}>{t('about_screen_team_heading')}</h2>
          <div className={'team_divider'}></div>
        </Grid>
        <Grid container className={'team_loop_image'}>
          {skills.map((skill) => (
            <Grid
              item
              key={skill.id}
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              sx={{ marginTop: '3rem' }}
            >
              <div className={'team_loop_container'}>
                <div className={'team_loop_container_div'}>
                  <div className={'team_loop_container_heading'}>
                    {skill.name}
                  </div>
                  <div className={'team_loop_container_subheading'}>
                    {skill.experience}
                  </div>
                  <div className={'team_loop_container_text'}>{skill.work}</div>
                  <div className={'team_loop_container_skills'}>
                    {skill.skills?.map((individualSkill) => (
                      <div
                        key={individualSkill}
                        className={'team_loop_container_skills_text'}
                      >
                        {individualSkill}
                      </div>
                    ))}
                  </div>
                </div>
                {skill.link !== '' ? (
                  <div
                    onClick={() => handleLinkClick(skill.link)}
                    style={{
                      color: '#0076FF',
                      fontSize: '20px',
                      fontStyle: 'normal',
                      fontWeight: 700,
                      lineHeight: 'normal',
                      textAlign: 'right',
                      cursor: 'pointer',
                    }}
                  >
                    in
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </>
  )
}

export default Team
