import i18n from 'i18next'

// LOGO
import CernLogo from '../../assets/logo-cern.png'
import TransparexLogo from '../../assets/logo-transparex.png'
import SouthWestRailwayLogo from '../../assets/logo-south-west-railway.png'
import GjensidigeLogo from '../../assets/logo-gjensidige.png'

// images
import CernDiagram from '../../assets/cern-diagram.png'
import TransparexIdea from '../../assets/transparex_idea.webp'
import TransparexSolution from '../../assets/transparex_solution.png'
import SouthWestRailwayIdea from '../../assets/south-west-railway-idea.webp'
import SouthWestRailwaySolution from '../../assets/south-west-railway-solution.png'
import GjensidigeIdea from '../../assets/gjensidige-idea.png'

// BACKGROUNDS
import CernBackground from '../../assets/cern-project-detail-hero-bg.webp'
import SouthWestTrainsBackground from '../../assets/southwest-trains-project-detail-hero-bg.webp'
import TransparexBackground from '../../assets/transparex-project-detail-hero-bg.webp'
import GjensidigeBackground from '../../assets/gjensidige-project-detail-hero-bg.webp'

// COLORS
import GreenLight from '../../assets/our_work_green.webp'
import BlueLight from '../../assets/our_work_blue.png'
import PurpleLight from '../../assets/our_work_purple.webp'
import YellowLight from '../../assets/our_work_yellow.webp'

export interface MobileProject {
  id: number
  routeId: string
  background: string
  heading: string
  subHeading: string
  icon: string
  challenge: string
  idea: string
  ideaImage: string
  solution: string
  solutionImage: string
  otherProjectsPromo: string
  color: string
}

export const MobileProjectData: MobileProject[] = [

  {
    id: 1,
      routeId: 'software-development-transparex',
    background: `${TransparexBackground}`,
    heading: 'Software development for TRANSPAREX',
    subHeading:
      'We have developed a modular system of microservices and aggregation services for data integration that merges diverse data types from various sources to generate comprehensive reports on a companies financial health.',
    icon: `${TransparexLogo}`,
    challenge:
      'TRANSPAREX is a platform for monitoring and streamlining public procurement in Slovakia. This innovative product has emerged in response to the pressing global issue of managing bids and suppliers within the procurement process, particularly pronounced in Slovakia. The task was to merge data from diverse registers including legal entities, individuals, cadastre, public procurement announcements, etc., into a cohesive and usable business representation.',
    idea: 'We will develop a microservices application designed as a modular system, where each microservice corresponds to a distinct registry. These interconnected datasets will be stored in a database, linking through individuals or companies. This approach enables us to efficiently retrieve records concerning who submitted which bids or is involved in various activities.',
    ideaImage: `${TransparexIdea}`,
    solution:
      "The final solution ingeniously breaks down the entire challenge into a modular system of microservices and aggregation services for data integration. It meets significant performance demands from clients, as it merges diverse data types from various sources to generate comprehensive reports on a company's financial health, all in accordance with specific rules. The seamless operation of TRANSPAREX is maintained through a robust High Availability architecture and comprehensive system replication.",
    solutionImage: `${TransparexSolution}`,
    otherProjectsPromo:
      'Explore more software development ventures of Singularity team.',
    color: `${PurpleLight}`,
  },
  {
    id: 2,
      routeId: 'software-development-southwest-trains',
    background: `${SouthWestTrainsBackground}`,
    heading: 'Software development for SOUTHWEST Trains',
    subHeading:
      'We have developed a large backend system that covers all ticket-related operations and is implemented on a national level.',
    icon: `${SouthWestRailwayLogo}`,
    challenge:
      'The Central Booking Engine (CBE) covers ticket sales for public transportation in half of England, including tickets for trains, buses, and the metro. The challenge is to solve the issue of nationwide ticket sales, considering the existence of various specialized ticket types and train services, along with their combinations. This includes group tickets, discounted tickets for juniors or seniors, and tickets with special reservations like those for bicycles. The client selects the departure and destination stations, and CBE has a 500ms window to calculate the optimal route while considering all the filters. For example, if a ticket requires a bicycle reservation, all trains on the route must have bicycle reservations.',
    idea: 'The plan is to create a large backend system that covers all ticket-related operations and can be distributed to be implemented on a national level.',
    ideaImage: `${SouthWestRailwayIdea}`,
    solution:
      'We have developed CBE as a massive SOAP WCF service, which handled web orders, point-of-sale orders, and Ticket Vending Machine (TVM) orders.',
    solutionImage: `${SouthWestRailwaySolution}`,
    otherProjectsPromo:
      'Explore more software development ventures of Singularity team.',
    color: `${YellowLight}`,
  },
  {
    id: 3,
      routeId: 'software-development-gjensidige-insurance-company',
    background: `${GjensidigeBackground}`,
    heading: 'Software development for Itera’s client: The largest Norwegian insurance company',
    subHeading:
      'We have developed an inter-connected microservice system to facilitate seamless communication among clients, medical professionals, and the insurance company. This cooperation was carried out as a subcontract.',
    icon: `${GjensidigeLogo}`,
    challenge:
      "The task at hand revolved around developing a health insurance module, specifically tailored for supplementary coverage. In this context, individuals involved in accidents would receive automatic compensation for vehicle damage. Moreover, this supplementary coverage extended to addressing subsequent issues. For instance, expenses related to chiropractic care for post-accident neck discomfort were covered. The system even factored in matters like post-traumatic stress, offering prepaid sessions with a psychiatrist. Importantly, this approach provided a unique advantage. The entire process operated iteratively from the client's perspective. Clients had the autonomy to select an available therapist and slot that suited their schedule, enabling them to book sessions with ease. After a session, the therapist would then notify the insurance company, validating session completion and associated coverage.",
    idea: 'The overarching plan involves setting up a microservice system to facilitate seamless communication among different stakeholders: clients, medical professionals, and the insurance company. Simultaneously, we will ensure that connection data is securely stored in an internal database.',
    ideaImage: `${GjensidigeIdea}`,
    solution:
      "The implemented solution closely resembled the proposed idea, with a notable exception being the security level of the insurance policy. It was deemed vital to maintain consistent safety regulations across all modules for streamlined maintenance. Operating entirely in the cloud, the company Group's insurance platform received thorough attention to cloud deployment and monitoring. For universal deployment, a combination of Terraform and OpenTelemetry was employed for effective monitoring. The entire solution resides on the Azure cloud infrastructure. React was chosen for the front end, while the back end relies on Spring and Hibernate. These components harmoniously interact through a Postgres database. In terms of inter-service communication, REST was chosen primarily based on corporate considerations. Although Kafka and GraphQL were evaluated, the decision was made to adhere to the REST approach.",
    solutionImage: '',
    otherProjectsPromo:
      'Explore more software development ventures of Singularity team.',
    color: `${GreenLight}`,
  },
]
