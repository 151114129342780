// IMAGES
import StartupDesign from '../../assets/startup_1.png'
import StartupBackend from '../../assets/startup_2.png'
import StartupArchitecture from '../../assets/startup_3.png'
import StartupPreview from '../../assets/startup_4.png'
import StartupDeploy from '../../assets/startup_5.png'
import StartupPerformance from '../../assets/startup_6.png'
import StartupColaborate from '../../assets/startup_7.png'

export interface MobileData {
  id: number
  heading: string
  text: string
  image: string
}

export const MobileSingularityProcessData: MobileData[] = [
  {
    id: 1,
    heading: 'Design',
    text: "Singularity wouldn't strive to be the tool it is without a seamless user experience. The design features are well-known, intuitive, and general, so there's no need to waste time relearning what you already know.",
    image: `${StartupDesign}`,
  },
  {
    id: 2,
    heading: 'Backend',
    text: 'The back-end provides access to libraries and hundreds of components that can be used to create your app.',
    image: `${StartupBackend}`,
  },
  {
    id: 3,
    heading: 'Architecture',
    text: "You have complete control over your application's cloud architecture and software delivery. You can adjust remote servers and set up restrictions for incoming and outgoing traffic.",
    image: `${StartupArchitecture}`,
  },
  {
    id: 4,
    heading: 'Preview',
    text: 'Before deployment, you can review every aspect and component in the Design, Back-end, and Architecture settings, ensuring nothing comes as a surprise.',
    image: `${StartupPreview}`,
  },
  {
    id: 5,
    heading: 'Deploy',
    text: "If everything checks out, you're just one click away from deploying your app and making it ready for market release.",
    image: `${StartupDeploy}`,
  },
  {
    id: 6,
    heading: 'Performance',
    text: 'With Singularity, you can assess cloud performance, response time, and the overall health of your application to identify potential errors.',
    image: `${StartupPerformance}`,
  },
  {
    id: 7,
    heading: 'Colaborate',
    text: 'Working on a large project can be overwhelming without an organized workforce. You can invite team members or external contributors to the Singularity project, define their roles, and set access restrictions.',
    image: `${StartupColaborate}`,
  },
]
