import React, { useState } from 'react'
import {
  Dialog,
  DialogContent,
  Typography,
  IconButton,
  TextField,
  Button,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'

interface CustomDialogProps {
  open: boolean
  onClose: () => void
}

const CustomDialog: React.FC<CustomDialogProps> = ({ open, onClose }) => {
  const { t } = useTranslation()
  const router = useNavigate()
  const CustomTextField = styled(TextField)`
    & .MuiOutlinedInput-root {
      color: white;
      background-color: black;

      & .MuiOutlinedInput-notchedOutline {
        border-color: white;
      }

      &:hover .MuiOutlinedInput-notchedOutline {
        border-color: white;
      }

      &.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: white;
      }

      & .MuiSelect-icon {
        color: white; // This targets the dropdown icon.
      }
    }
  `

  const textFieldStyles = {
    color: 'white',
    backgroundColor: 'black',
  }

  const [email, setEmail] = useState<string>('')
  const [errorEmail, setErrorEmail] = useState<boolean>(false)

  const handleEmailChange = (input: string) => {
    setEmail(input)
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

    const validEmail = emailRegex.test(input)

    if (!validEmail) {
      setErrorEmail(true)
      return
    }

    setErrorEmail(false)
  }

  const handleRedirect = (email: string) => {
    router(`/contact/${email}`)
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogContent
        style={{
          backgroundColor: '#171717',
          position: 'relative',
          padding: '4rem',
        }}
      >
        <IconButton
          style={{
            position: 'absolute',
            right: '1rem',
            top: '1rem',
            color: 'white',
          }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>

        <Typography
          align="center"
          style={{
            fontSize: '20px',
            fontWeight: 400,
            lineHeight: 'normal',
            fontStyle: 'normal',
            color: '#0076FF',
          }}
        >
          {t('singularity_popup_subheading')}
        </Typography>
        <Typography
          align="center"
          style={{
            fontSize: '48px',
            fontWeight: 400,
            lineHeight: '60px',
            fontStyle: 'normal',
            color: 'white',
          }}
        >
          {t('singularity_popup_heading')}
        </Typography>

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '1rem',
          }}
        >
          <div
            style={{ backgroundColor: '#0076FF', height: '2px', width: '64px' }}
          ></div>
        </div>

        <Typography
          align="center"
          style={{
            marginTop: '1rem',
            fontSize: '16px',
            fontWeight: 300,
            lineHeight: '24px',
            fontStyle: 'normal',
            color: 'white',
            whiteSpace: 'pre-line',
          }}
        >
          {t('singularity_popup_text')}
        </Typography>

        <Typography
          align="left"
          style={{
            marginTop: '1rem',
            fontSize: '14px',
            fontWeight: 300,
            lineHeight: 'normal',
            fontStyle: 'normal',
            color: 'white',
          }}
        >
          {t('contact_form_email')}
        </Typography>

        <TextField
          InputProps={{
            style: textFieldStyles,
            classes: {
              notchedOutline: classNames({
                custom_outline: true,
                error_outline: errorEmail,
              }),
            },
          }}
          fullWidth
          variant="outlined"
          type="email"
          value={email}
          onChange={(e) => handleEmailChange(e.target.value)}
          placeholder="Enter your email address..."
          style={{ marginTop: '.5rem', backgroundColor: 'black' }}
          error={errorEmail}
          helperText={errorEmail ? 'Invalid email address' : ''}
        />
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            onClick={() => handleRedirect(email)}
            variant="contained"
            style={{
              marginTop: '1.5rem',
              padding: '20px 48px',
              borderRadius: 0,
            }}
            color="primary"
          >
            {t('contact_form_send_button')}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default CustomDialog
