// IMAGES
import StartupDesign from '../../assets/startup_1.png'
import StartupBackend from '../../assets/startup_2.png'
import StartupArchitecture from '../../assets/startup_3.png'
import StartupPreview from '../../assets/startup_4.png'
import StartupDeploy from '../../assets/startup_5.png'
import StartupPerformance from '../../assets/startup_6.png'
import StartupColaborate from '../../assets/startup_7.png'

export interface Data {
  id: number
  heading: string
  text: string
  image: string
}

export const SingularityProcessData: Data[] = [
  {
    id: 1,
    heading: 'Design',
    text: "Singularity wouldn't strive to be the tool it is \n without a seamless user experience. The design \n features are well-known, intuitive, and general, \n so there's no need to waste time relearning what \n you already know.",
    image: `${StartupDesign}`,
  },
  {
    id: 2,
    heading: 'Backend',
    text: 'The back-end provides access to libraries and \n hundreds of components that can be used to \n create your app.',
    image: `${StartupBackend}`,
  },
  {
    id: 3,
    heading: 'Architecture',
    text: "You have complete control over your \n application's cloud architecture and software \n delivery. You can adjust remote servers and set \n up restrictions for incoming and outgoing traffic.",
    image: `${StartupArchitecture}`,
  },
  {
    id: 4,
    heading: 'Preview',
    text: 'Before deployment, you can review every aspect \n and component in the Design, Back-end, and \n Architecture settings, ensuring nothing comes as \n a surprise.',
    image: `${StartupPreview}`,
  },
  {
    id: 5,
    heading: 'Deploy',
    text: "If everything checks out, you're just one click \n away from deploying your app and making it \n ready for market release.",
    image: `${StartupDeploy}`,
  },
  {
    id: 6,
    heading: 'Performance',
    text: 'With Singularity, you can assess cloud \n performance, response time, and the overall \n health of your application to identify potential \n errors.',
    image: `${StartupPerformance}`,
  },
  {
    id: 7,
    heading: 'Colaborate',
    text: 'Working on a large project can be overwhelming \n without an organized workforce. You can invite \n team members or external contributors to the \n Singularity project, define their roles, and set \n access restrictions.',
    image: `${StartupColaborate}`,
  },
]
