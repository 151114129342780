import React from 'react'
import { Button, useMediaQuery, useTheme } from '@mui/material'
import Grid from '@mui/material/Grid'
import { useNavigate } from 'react-router-dom'
import ArrowForward from '@mui/icons-material/ArrowForward'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import '../../styles/Home/ContactUs.css'
import { useTranslation } from 'react-i18next'

const ContactUs: React.FC = () => {
  const { t } = useTranslation()
  const router = useNavigate()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const button_style = {
    padding: '20px 24px',
    textAlign: 'center',
    borderRadius: '0px',
    border: '1px solid #FFF',
    color: '#FFF',
    marginLeft: 'auto',
    textTransform: 'initial',
  }

  const handleButtonClick = () => {
    router('/contact')
  }

  if (isMobile) {
    return (
      <div style={{ width: '90%' }} className={'contact_container'}>
        <div className={'contact_mail_address'}>
          <MailOutlineIcon className={'contact_icon'} />{' '}
          sales@globalsingularity.io
        </div>
        <div className={'contact_grid'}>
          <Button
            onClick={handleButtonClick}
            variant="outlined"
            sx={button_style}
          >
            {t('singularity_contact_us_button')}
            <ArrowForward sx={{ marginLeft: '1rem' }} />
          </Button>
        </div>
        <div className={'contact_line_div'}></div>
      </div>
    )
  }

  return (
    <>
      <Grid container className={'contact_container'}>
        <Grid item className={'contact_grid'} xs={12}>
          <div className={'contact_mail_address'}>
            <MailOutlineIcon className={'contact_icon'} />{' '}
            sales@globalsingularity.io
          </div>
          <Button
            onClick={handleButtonClick}
            variant="outlined"
            sx={button_style}
          >
            {t('singularity_contact_us_button')}
            <ArrowForward sx={{ marginLeft: '1rem' }} />
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

export default ContactUs
