import React from 'react'
import { Button, useMediaQuery, useTheme } from '@mui/material'
import Grid from '@mui/material/Grid'
import Background from '../../assets/startup-homepage-hero-bg.webp'
import '../../styles/Home/SingularityStartup.css'
import { useTranslation } from 'react-i18next'

const handleButtonClick = () => {
  const url =
    'https://calendly.com/sales-9gm/consultation-with-singularity-agency/consultation-for-singularity-agency'
  window.open(url, '_blank')
}

const SingularityStartup: React.FC = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  if (isMobile) {
    return (
      <>
        <Grid container className="startup_container">
          <Grid item xs={12} className="startup_grid">
            <div className="startup_mobile_section">
              <div className="startup_subheading">
                {t('home_screen_singularity_startup_subheading')}
              </div>
              <h3 className="startup_heading">
                {t('home_screen_singularity_startup_heading')}
              </h3>
              <div className="startup_divider"></div>
              <div className="startup_text">
                {t('home_screen_singularity_startup_text')}
              </div>
              <Button
                onClick={handleButtonClick}
                variant="contained"
                sx={{
                  padding: '20px 24px',
                  backgroundColor: '#0076FF',
                  textAlign: 'center',
                  marginTop: '2rem',
                  borderRadius: '0px',
                  color: '#FFF',
                  textTransform: 'initial',
                }}
              >
                {t('home_screen_singularity_startup_button')}
              </Button>
            </div>
          </Grid>
        </Grid>
      </>
    )
  }

  return (
    <>
      <Grid container className="startup_container">
        <Grid item xs={12} className="startup_grid">
          <img className="startup_image" src={Background} alt="Background" />
          <div className="startup_subheading">
            {t('home_screen_singularity_startup_subheading')}
          </div>
          <h3 className="startup_heading">
            {t('home_screen_singularity_startup_heading')}
          </h3>
          <div className="startup_divider"></div>
          <div className="startup_text">
            {t('home_screen_singularity_startup_text')}
          </div>
          <Button
            onClick={handleButtonClick}
            variant="contained"
            sx={{
              padding: '20px 24px',
              backgroundColor: '#0076FF',
              textAlign: 'center',
              marginTop: '2rem',
              borderRadius: '0px',
              color: '#FFF',
              textTransform: 'initial',
            }}
          >
            {t('home_screen_singularity_startup_button')}
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

export default SingularityStartup
