// ICONS
import DesignIcon from '../../assets/design-icon.png'
import DatabaseIcon from '../../assets/database-icon.png'
import ArchitectureIcon from '../../assets/architecture-icon.png'

// IMAGES
import StartupBoostYourProductivity from '../../assets/startup-boost-your-productivity.png'
import StartupSaveCostDeliverFaster from '../../assets/startup-save-cost-deliver-faster.png'
import StartupTogetherFaster from '../../assets/startup-together-faster.png'

export interface MobileData {
  id: number
  heading: string
  text: string
  image: string
}

export const MobileSingularityData: MobileData[] = [
  {
    id: 1,
    heading: 'Deploy apps rapidly',
    text: 'Harness the power of Singularity tool for accelerated app deployment, reducing time-to-market significantly.',
    image: `${StartupTogetherFaster}`,
  },
  {
    id: 2,
    heading: 'Save cost deliver faster',
    text: 'Utilize our AI-powered development with Singularity to efficiently manage resources, curbing expenses, and achieve swifter project delivery.',
    image: `${StartupSaveCostDeliverFaster}`,
  },
  {
    id: 3,
    heading: 'Boost your productivity',
    text: 'Experience a remarkable increase in your productivity through optimized processes and enhancing features.',
    image: `${StartupBoostYourProductivity}`,
  },
]

export const MobileSingularityCards: MobileData[] = [
  {
    id: 1,
    heading: 'Design',
    text: "With an easy wireframing and drag-and-drop editor, you can create a front-end that complements the AI's code in just a few minutes.",
    image: `${DesignIcon}`,
  },
  {
    id: 2,
    heading: 'Database',
    text: 'Choose the database of your preference directly within the system. After deploying your app, you will have direct access to your cloud space.',
    image: `${DatabaseIcon}`,
  },
  {
    id: 3,
    heading: 'Architecture',
    text: "You can rely on Singularity's architectural approach, and also customize it to your preferences. Enhance the app's architecture, tweak it, or completely change it to suit your needs.",
    image: `${ArchitectureIcon}`,
  },
]
