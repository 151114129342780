import React from 'react'
import { Button, useMediaQuery, useTheme } from '@mui/material'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Background from '../../assets/startup-homepage-hero-bg.webp'
import { useTranslation } from 'react-i18next'
import '../../styles/SingularityStartup/SingularityStartupPromo.css'

const SingularityStartupPromo: React.FC = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const handleButtonClick = () => {
    const url =
      'https://calendly.com/sales-9gm/consultation-with-singularity-ai/consultation-for-singularity-agency'
    window.open(url, '_blank')
  }

  if (isMobile) {
    return (
      <>
        <Box>
          <Grid container className={'singularity_startup_mobile_grid'}>
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <h2
                style={{
                  marginTop: '0px',
                  marginBottom: '0px',
                  paddingTop: '6rem',
                  fontSize: '32px',
                  fontWeight: '400',
                  fontStyle: 'normal',
                  lineHeight: '110%',
                  textAlign: 'center',
                  color: '#FFF',
                  whiteSpace: 'pre-line',
                }}
              >
                {t('singularity_startup_demo_heading')}
              </h2>
              <div
                style={{
                  textAlign: 'center',
                  marginTop: '1rem',
                  backgroundColor: '#0076FF',
                  height: '2px',
                  width: '64px',
                }}
              ></div>
              <div
                style={{
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: '300',
                  lineHeight: '24px',
                  marginTop: '2rem',
                  padding: '0 2rem',
                  textAlign: 'center',
                  color: '#AAA',
                }}
              >
                {t('singularity_startup_demo_text')}
              </div>
              <Button
                onClick={handleButtonClick}
                variant="contained"
                sx={{
                  padding: '20px 24px',
                  textTransform: 'initial',
                  textAlign: 'center',
                  marginTop: '2rem',
                  borderRadius: '0px',
                  color: '#FFF',
                  backgroundColor: '#0076FF',
                }}
              >
                {t('singularity_startup_demo_button')}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </>
    )
  }

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <img
          src={Background}
          alt="Background"
          style={{
            position: 'absolute',
            left: 0,
            height: '80%',
            width: '100%',
            zIndex: -1,
          }}
        />
        <Grid container>
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              paddingTop: '10rem',
            }}
          >
            <h2
              style={{
                marginTop: '0px',
                marginBottom: '0px',
                fontSize: '32px',
                fontWeight: '400',
                fontStyle: 'normal',
                lineHeight: '110%',
                textAlign: 'center',
                color: '#FFF',
                whiteSpace: 'pre-line',
              }}
            >
              {t('singularity_startup_demo_heading')}
            </h2>
            <div
              style={{
                textAlign: 'center',
                marginTop: '1rem',
                backgroundColor: '#0076FF',
                height: '2px',
                width: '64px',
              }}
            ></div>
            <div
              style={{
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: '300',
                lineHeight: '24px',
                marginTop: '2rem',
                padding: '0 2rem',
                textAlign: 'center',
                color: '#AAA',
              }}
            >
              {t('singularity_startup_demo_text')}
            </div>
            <Button
              onClick={handleButtonClick}
              variant="contained"
              sx={{
                padding: '20px 24px',
                textTransform: 'initial',
                textAlign: 'center',
                marginTop: '2rem',
                borderRadius: '0px',
                color: '#FFF',
                backgroundColor: '#0076FF',
              }}
            >
              {t('singularity_startup_demo_button')}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default SingularityStartupPromo
