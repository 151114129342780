import React from 'react'
import Grid from '@mui/material/Grid'
import PageHeroBg from '../assets/page-hero-bg.webp'
import ProjectsOverview from '../components/Work/ProjectsOverview'
import ContactUs from '../components/Home/ContactUs'
import Icons from '../components/Home/Icons'
import '../styles/Work/Work.css'
import { useMediaQuery, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'

const Work: React.FC = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <>
      <Grid container>
        <img src={PageHeroBg} alt="Logo" className={'work_image'} />
        <Grid item xs={12} className={'work_grid'} justifyContent="center">
          <div className={'work_subheading'}>{t('our_work_subheading')}</div>
          <h1 className={'work_heading'}>{t('our_work_heading')}</h1>
          <div className={'work_text'}>{t('our_work_text')}</div>
        </Grid>
      </Grid>
      <ProjectsOverview />
      <ContactUs />
      {isMobile ? <div></div> : <Icons />}
    </>
  )
}

export default Work
