import React from 'react'
import Grid from '@mui/material/Grid'
import HomepageLogoReact from '../../assets/homepage-logo-react.png'
import HomepageLogoAngular from '../../assets/homepage-logo-angular.png'
import HomepageLogoJava from '../../assets/homepage-logo-java.png'
import HomepageLogoSpring from '../../assets/homepage-logo-spring.png'
import HomepageLogoHibernate from '../../assets/homepage-logo-hibernate.png'
import '../../styles/Home/Icons.css'
import { useMediaQuery, useTheme } from '@mui/material'

const Icons: React.FC = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const items = [
    { id: 1, icon: HomepageLogoReact, width: 150, height: 50 },
    { id: 2, icon: HomepageLogoAngular, width: 150, height: 50 },
    { id: 3, icon: HomepageLogoJava, width: 125, height: 57 },
    { id: 4, icon: HomepageLogoSpring, width: 150, height: 50 },
    { id: 5, icon: HomepageLogoHibernate, width: 150, height: 50 },
  ]

  if (isMobile) {
    return (
      <>
        <Grid container className={'mobile_icons_container'}>
          {items.map((item) => (
            <Grid item xs={12} className={'mobile_icons_grid'}>
              <img
                src={item.icon}
                alt="Icons"
                key={item.id}
                className={'mobile_icons_icon'}
              />
            </Grid>
          ))}
        </Grid>
      </>
    )
  }

  return (
    <Grid container className={'icons_container'}>
      <Grid item className={'icons_grid'}>
        {items.map((item) => (
          <img
            src={item.icon}
            alt="Icons"
            key={item.id}
            className={'icons_icon'}
            width={item.width}
            height={item.height}
          />
        ))}
      </Grid>
    </Grid>
  )
}

export default Icons
