// ICONS
import SoftwareDevIcon from '../../assets/software-dev-icon.png'
import ApplicationRefactoringIcon from '../../assets/aplication-refactoring-icon.png'
import ArchitectureReviewIcon from '../../assets/architecture-review-icon.png'
import SoftwareMaintenanceIcon from '../../assets/software-maintenance-icon.png'
import ConsultingIcon from '../../assets/consulting-icon.png'

// BACKGROUNDS
import ServicesApplicationRefactoring from '../../assets/services-application-refactoring.png'
import ServicesArchitectureReview from '../../assets/services-architecture-review.png'
import ServicesConsulting from '../../assets/services-consulting.png'
import ServicesSoftwareDev from '../../assets/services-software-dev.png'
import ServicesSoftwareMaintenance from '../../assets/services-software-maintenance.png'

export interface Service {
  id: number
  heading: string
  text: string
  icon: string
  background: string
}

export const ServiceData: Service[] = [
  {
    id: 1,
    heading: 'Software \n development',
    text: 'From the initial brief, and analysis of the current \n state, through design, and development, to \n deployment and long-term maintenance - we \n provide a complete life cycle of customized \n application development. Thanks to many years \n of experience in various sectors, we are rarely \n surprised by a task.',
    icon: `${SoftwareDevIcon}`,
    background: `${ServicesSoftwareDev}`,
  },
  {
    id: 2,
    heading: 'Application \n refactoring',
    text: 'While old apps may still hold value, without \n proper maintenance over time, they can lose \n compatibility. Application refactoring is a way to \n breathe new life into these applications. We will \n preserve their original functionality and \n interface, while also ensuring a seamless \n transition to cloud platforms that align with \n current security standards.',
    icon: `${ApplicationRefactoringIcon}`,
    background: `${ServicesApplicationRefactoring}`,
  },
  {
    id: 3,
    heading: 'Architecture \n review',
    text: "When a developer works on a project for an \n extended period, objectivity can decline. The \n solution is an architecture review. We will assess \n the frameworks used, the code structure, and \n their impact on the application's functionality, \n performance, or speed, and then provide \n recommendations for improvements.",
    icon: `${ArchitectureReviewIcon}`,
    background: `${ServicesArchitectureReview}`,
  },
  {
    id: 4,
    heading: 'Software \n maintenance',
    text: "Applications require ongoing maintenance. This \n can be covered either through long-term \n collaboration, where we oversee the software \n we've developed, or by providing maintenance \n for applications that you do not have internal \n resources to manage.",
    icon: `${SoftwareMaintenanceIcon}`,
    background: `${ServicesSoftwareMaintenance}`,
  },
  {
    id: 5,
    heading: 'Consulting',
    text: 'Our services encompass consultations as well. \n Consulting is included in the service price when \n you opt for custom development, refactoring, or \n architecture review. However, certain cases may \n demand separate consulting, distinct from code \n development. And who better to offer guidance \n than our experienced team of programmers?',
    icon: `${ConsultingIcon}`,
    background: `${ServicesConsulting}`,
  },
]
