import React from 'react'
import SingularityStartupProcessView from '../components/SingularityStartup/SingularityStartupProcessView'
import ContactUs from '../components/Home/ContactUs'

const SingularityStartupProcess: React.FC = () => {
  return (
    <>
      <SingularityStartupProcessView />
      <ContactUs />
    </>
  )
}

export default SingularityStartupProcess
