import React, { useEffect, useState } from 'react'
import {
  Card,
  CardContent,
  FormControl,
  Typography,
  Slider,
  Select,
  MenuItem,
  useTheme,
  useMediaQuery,
  Button,
  Popover,
  makeStyles,
} from '@mui/material'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import styled from '@emotion/styled'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

interface DeveloperType {
  id: number
  type: string
  price: number
  counter: number | null
}

interface DeveloperCategory {
  category: string
  data: DeveloperType[]
}

const SingularityStartupCalculator: React.FC = () => {
  const { t } = useTranslation()
  const [projectLength, setProjectLength] = useState<number | number[]>(0)
  const [hideMaxLabel, setHideMaxLabel] = useState(false)

  const [sum, setSum] = useState<number>(0)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  function formatSum(parsedNumber: number) {
    if (parsedNumber >= 1000) {
      return (parsedNumber / 1000).toFixed(1) + 'k'
    }
    return parsedNumber.toString()
  }

  function formatOurPrice(parsedNumber: number) {
    let newPrice = parsedNumber * 0.3
    if (newPrice >= 1000) {
      return (newPrice / 1000).toFixed(1) + 'k'
    }
    return newPrice.toString()
  }

  function formatValue(parsedNumber: number) {
    if (parsedNumber === 1) {
      return parsedNumber + 'month'
    }
    return parsedNumber + ' months'
  }

  const handleSliderChange = (
    event: Event,
    value: number | number[],
    activeThumb: number,
  ) => {
    const closeToMax =
      typeof value === 'number' && (60 - value <= 6 || 1 + value <= 8)
    setHideMaxLabel(closeToMax)
    setProjectLength(value)
  }

  const customTheme = createTheme({
    components: {
      MuiSlider: {
        styleOverrides: {
          markLabel: hideMaxLabel
            ? { visibility: 'hidden' }
            : { color: '#AAA' },
          valueLabel: {
            color: '#0076FF',
            backgroundColor: 'transparent',
            top: 50,
          },
        },
      },
    },
  })

  const textFieldStyles = {
    color: 'white',
    backgroundColor: '#0F0F0F',
    marginBottom: '1rem',
    marginTop: '1rem',
  }

  const CustomFormControl = styled(FormControl)`
    & .MuiOutlinedInput-root {
      color: white;
      background-color: #0f0f0f;

      & .MuiOutlinedInput-notchedOutline {
        border-color: white;
      }

      &:hover .MuiOutlinedInput-notchedOutline {
        border-color: white;
      }

      &.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: white;
      }

      & .MuiSelect-icon {
        color: white;
      }
    }
  `

  // Array to hold the anchor elements for the 6 popovers
  const [anchorEl, setAnchorEl] = useState([null, null, null, null, null, null])

  const [developers, setDevelopers] = useState<DeveloperCategory[]>([
    {
      category: 'Frontend developer',
      data: [
        {
          id: 1,
          type: 'Junior frontend developer - $2500',
          price: 2500,
          counter: 0,
        },
        {
          id: 2,
          type: 'Medior frontend developer - $3500',
          price: 3500,
          counter: 0,
        },
        {
          id: 3,
          type: 'Senior frontend developer - $5000',
          price: 5000,
          counter: 0,
        },
      ],
    },
    {
      category: 'Backend developer',
      data: [
        {
          id: 1,
          type: 'Junior backend developer - $2500',
          price: 2500,
          counter: 0,
        },
        {
          id: 2,
          type: 'Medior backend developer - $3500',
          price: 3500,
          counter: 0,
        },
        {
          id: 3,
          type: 'Senior backend developer - $5000',
          price: 5000,
          counter: 0,
        },
      ],
    },
    {
      category: 'Project manager',
      data: [
        { id: 1, type: 'Junior developer - $1500', price: 1500, counter: 0 },
        { id: 2, type: 'Senior developer - $3000', price: 3000, counter: 0 },
      ],
    },
    {
      category: 'Designer',
      data: [
        { id: 1, type: 'Junior - $2500', price: 2500, counter: 0 },
        { id: 2, type: 'Medior - $3500', price: 3500, counter: 0 },
        { id: 3, type: 'Senior - $5000', price: 5000, counter: 0 },
      ],
    },
    {
      category: 'Architect',
      data: [
        { id: 1, type: 'Medior architect - $5000', price: 5000, counter: 0 },
        { id: 2, type: 'Senior architect - $7000', price: 7000, counter: 0 },
      ],
    },
    {
      category: 'Devops',
      data: [
        { id: 1, type: 'Junior developer - $2500', price: 2500, counter: 0 },
        { id: 2, type: 'Medior developer - $4000', price: 4000, counter: 0 },
        { id: 3, type: 'Senior developer - $7000', price: 7000, counter: 0 },
      ],
    },
  ])

  useEffect(() => {
    const calculateProjectPrice = () => {
      let totalPrice: number = 0

      developers.forEach((developerCategory) => {
        developerCategory.data.forEach((developer) => {
          const price = developer.price // Assuming each 'developer' object has a 'price' property
          const count = developer.counter ?? 0 // Using nullish coalescing to default to 0 if counter is null
          totalPrice += price * count
        })
      })

      if (typeof projectLength === 'number') {
        totalPrice *= projectLength
      }

      setSum(totalPrice)
    }

    calculateProjectPrice()
  }, [developers, projectLength]) // Include 'developers' and 'projectLength' as dependencies

  const handleClick = (event: any, index: any) => {
    const newAnchorEl = [...anchorEl]
    newAnchorEl[index] = event.currentTarget
    setAnchorEl(newAnchorEl)
  }

  const handleClose = (index: any) => {
    const newAnchorEl = [...anchorEl]
    newAnchorEl[index] = null
    setAnchorEl(newAnchorEl)
  }

  const updateCounter = (
    categoryIndex: any,
    developerIndex: any,
    value: any,
  ) => {
    const newDevelopers = [...developers]
    newDevelopers[categoryIndex].data[developerIndex].counter =
      value === '' ? null : Number(value)

    // Update the whole state
    setDevelopers(newDevelopers)
  }

  if (isMobile) {
    return (
      <ThemeProvider theme={customTheme}>
        <div
          style={{
            paddingBottom: '2rem',
            paddingTop: '4rem',
            fontFamily: 'Axiforma',
          }}
        >
          <Grid container>
            <Grid item xs={12}>
              <div
                style={{
                  fontFamily: 'Axiforma',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  paddingTop: '10rem',
                  padding: '0 2rem',
                }}
              >
                <h2
                  style={{
                    marginTop: '0px',
                    marginBottom: '0px',
                    fontFamily: 'Axiforma',
                    fontSize: '48px',
                    fontWeight: '400',
                    fontStyle: 'normal',
                    lineHeight: '60px',
                    textAlign: 'center',
                    color: '#FFF',
                    whiteSpace: 'pre-line',
                  }}
                >
                  {t('singularity_pricing_calculator_heading')}
                </h2>
                <div
                  style={{
                    fontFamily: 'Axiforma',
                    textAlign: 'center',
                    marginTop: '1rem',
                    backgroundColor: '#0076FF',
                    height: '2px',
                    width: '64px',
                  }}
                ></div>
                <div
                  style={{
                    fontFamily: 'Axiforma',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: '300',
                    lineHeight: '24px',
                    marginTop: '1rem',
                    textAlign: 'center',
                    color: '#AAA',
                    whiteSpace: 'pre-line',
                  }}
                >
                  {t('singularity_pricing_calculator_text')}
                </div>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ marginTop: '2rem', fontFamily: 'Axiforma' }}
            >
              <Card
                style={{
                  fontFamily: 'Axiforma',
                  backgroundColor: '#171717',
                  color: 'white',
                  padding: '2rem',
                  borderRadius: '0px',
                  margin: '2rem',
                }}
              >
                <CardContent>
                  <Typography
                    style={{
                      fontFamily: 'Axiforma',
                      fontSize: '20px',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      lineHeight: 'normal',
                    }}
                  >
                    {t('singularity_calculator_heading')}
                  </Typography>
                  <Slider
                    value={projectLength}
                    onChange={handleSliderChange}
                    valueLabelDisplay="on"
                    valueLabelFormat={(value) => formatValue(value)}
                    marks={[
                      { value: 1, label: '1 month' },
                      { value: 60, label: '60 months' },
                    ]}
                    min={1}
                    max={60}
                    step={1}
                    style={{
                      marginTop: '20px',
                      marginBottom: '20px',
                      fontFamily: 'Axiforma',
                    }}
                  />
                  {/* Two selects */}
                  <Grid container spacing={2} style={{ marginTop: '1rem' }}>
                    {developers.map((developerCategory, categoryIndex) => (
                      <Grid item xs={12} key={categoryIndex}>
                        <Typography
                          style={{
                            fontFamily: 'Axiforma',
                            fontSize: '18px',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            lineHeight: 'normal',
                            marginBottom: '1rem',
                          }}
                        >
                          {categoryIndex === 0
                            ? t('singularity_calculator_select1')
                            : categoryIndex === 1
                            ? t('singularity_calculator_select2')
                            : categoryIndex === 2
                            ? t('singularity_calculator_select3')
                            : categoryIndex === 3
                            ? t('singularity_calculator_select4')
                            : categoryIndex === 4
                            ? t('singularity_calculator_select5')
                            : categoryIndex === 5
                            ? t('singularity_calculator_select6')
                            : ''}
                        </Typography>
                        <div
                          style={{ marginTop: '20px', fontFamily: 'Axiforma' }}
                        >
                          <Button
                            variant="outlined"
                            style={{
                              color: 'white',
                              borderColor: 'white',
                              width: '100%',
                              fontFamily: 'Axiforma',
                            }}
                            onClick={(e) => handleClick(e, categoryIndex)}
                          >
                            {developerCategory.category}
                          </Button>
                          <Popover
                            open={Boolean(anchorEl[categoryIndex])}
                            anchorEl={anchorEl[categoryIndex]}
                            onClose={() => handleClose(categoryIndex)}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'center',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'center',
                            }}
                          >
                            <div
                              style={{
                                padding: '20px',
                                backgroundColor: '#0F0F0F',
                                fontFamily: 'Axiforma',
                              }}
                            >
                              {developerCategory.data.map(
                                (developer, developerIndex) => (
                                  <div
                                    key={developerIndex}
                                    style={{
                                      color: 'white',
                                      fontFamily: 'Axiforma',
                                    }}
                                  >
                                    <Typography
                                      variant="body2"
                                      style={{
                                        color: 'white',
                                        fontFamily: 'Axiforma',
                                      }}
                                    >
                                      {developer.type}
                                    </Typography>
                                    <TextField
                                      type="number"
                                      variant="outlined"
                                      InputLabelProps={{
                                        style: {
                                          color: 'white',
                                          fontFamily: 'Axiforma',
                                        },
                                      }}
                                      InputProps={{
                                        style: textFieldStyles,
                                        classes: {
                                          notchedOutline: classNames({
                                            custom_outline: true,
                                          }),
                                        },
                                      }}
                                      inputProps={{
                                        min: 0,
                                      }}
                                      value={
                                        developer.counter !== null
                                          ? developer.counter
                                          : ''
                                      }
                                      onChange={(e) =>
                                        updateCounter(
                                          categoryIndex,
                                          developerIndex,
                                          e.target.value,
                                        )
                                      }
                                      style={{
                                        marginLeft: '20px',
                                        borderColor: 'white',
                                        fontFamily: 'Axiforma',
                                      }}
                                    />
                                  </div>
                                ),
                              )}
                            </div>
                          </Popover>
                          <div>
                            {developerCategory.data.map(
                              (developer, developerIndex) =>
                                (developer.counter ?? 0) >= 1 ? (
                                  <Typography
                                    key={developerIndex}
                                    variant="body2"
                                    style={{
                                      marginTop: '.5rem',
                                      fontFamily: 'Axiforma',
                                    }}
                                  >
                                    {`${developer.type} (${developer.counter})`}
                                  </Typography>
                                ) : null,
                            )}
                          </div>
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginTop: '2rem',
                      fontFamily: 'Axiforma',
                    }}
                  >
                    <div
                      style={{
                        width: '90%',
                        height: '1px',
                        backgroundColor: '#FFF',
                        opacity: '0.24',
                        fontFamily: 'Axiforma',
                      }}
                    ></div>
                  </div>
                  <Grid
                    container
                    spacing={2}
                    style={{ marginTop: '1rem', fontFamily: 'Axiforma' }}
                  >
                    <Grid item xs={12}>
                      <Typography
                        style={{
                          fontSize: '20px',
                          fontWeight: 400,
                          fontStyle: 'normal',
                          lineHeight: 'normal',
                          color: 'white',
                          fontFamily: 'Axiforma',
                        }}
                      >
                        {t('singularity_calculator_footer_heading')}
                      </Typography>
                    </Grid>

                    <Grid container item xs={12} justifyContent="space-between">
                      <Grid item>
                        <Typography
                          style={{
                            fontSize: '24px',
                            fontWeight: 300,
                            fontStyle: 'normal',
                            lineHeight: 'normal',
                            color: '#AAA',
                            fontFamily: 'Axiforma',
                          }}
                        >
                          ${formatSum(sum)}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          style={{
                            fontSize: '24px',
                            fontWeight: 300,
                            fontStyle: 'normal',
                            lineHeight: 'normal',
                            color: 'white',
                            fontFamily: 'Axiforma',
                          }}
                        >
                          ${formatOurPrice(sum)}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid item xs={6}>
                      <Typography
                        style={{
                          fontSize: '12px',
                          fontWeight: 300,
                          fontStyle: 'normal',
                          lineHeight: 'normal',
                          color: '#AAA',
                          letterSpacing: '0.48px',
                          fontFamily: 'Axiforma',
                        }}
                      >
                        {t('singularity_calculator_footer_cost')}
                      </Typography>
                    </Grid>

                    <Grid item xs={6}>
                      <Typography
                        style={{
                          fontSize: '12px',
                          fontWeight: 300,
                          fontStyle: 'normal',
                          lineHeight: 'normal',
                          color: 'white',
                          letterSpacing: '0.48px',
                          fontFamily: 'Axiforma',
                        }}
                      >
                        {t('singularity_calculator_footer_singularity_cost')}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Card
                style={{
                  backgroundColor: '#171717',
                  color: 'white',
                  padding: '2rem',
                  margin: '0 2rem 0 2rem',
                  borderRadius: '0px',
                  fontFamily: 'Axiforma',
                }}
              >
                <CardContent>
                  <Typography
                    style={{
                      fontSize: '24px',
                      fontWeight: 600,
                      fontStyle: 'normal',
                      lineHeight: 'normal',
                      color: 'white',
                      fontFamily: 'Axiforma',
                    }}
                  >
                    {t('singularity_calculator_card1_heading')}
                  </Typography>
                  <div
                    style={{
                      textAlign: 'center',
                      marginTop: '1rem',
                      backgroundColor: '#0076FF',
                      height: '2px',
                      width: '32px',
                      fontFamily: 'Axiforma',
                    }}
                  ></div>
                  <Typography
                    style={{
                      marginTop: '1rem',
                      fontSize: '14px',
                      fontWeight: 300,
                      fontStyle: 'normal',
                      lineHeight: 'normal',
                      color: '#AAA',
                      whiteSpace: 'pre-line',
                      fontFamily: 'Axiforma',
                    }}
                  >
                    {t('singularity_calculator_card1_subheading')}
                  </Typography>
                  <Typography
                    style={{
                      marginTop: '1rem',
                      fontSize: '32px',
                      fontWeight: 300,
                      fontStyle: 'normal',
                      lineHeight: 'normal',
                      color: '#AAA',
                      whiteSpace: 'pre-line',
                      fontFamily: 'Axiforma',
                    }}
                  >
                    + ${formatSum(sum)}
                  </Typography>
                  <Typography
                    style={{
                      marginTop: '.25rem',
                      fontSize: '12px',
                      fontWeight: 300,
                      fontStyle: 'normal',
                      lineHeight: 'normal',
                      letterSpacing: '0.48px',
                      color: '#AAA',
                      whiteSpace: 'pre-line',
                      fontFamily: 'Axiforma',
                    }}
                  >
                    {t('singularity_calculator_card1_text')}
                  </Typography>
                </CardContent>
              </Card>
              <Grid item xs>
                <Card
                  style={{
                    height: '60%',
                    padding: '2rem',
                    margin: '2rem 2rem 0 2rem',
                    backgroundColor: '#0076FF',
                    color: 'white',
                    borderRadius: '0px',
                    fontFamily: 'Axiforma',
                  }}
                >
                  <CardContent>
                    <Typography
                      style={{
                        fontSize: '24px',
                        fontWeight: 600,
                        fontStyle: 'normal',
                        lineHeight: 'normal',
                        color: 'white',
                        fontFamily: 'Axiforma',
                      }}
                    >
                      {t('singularity_calculator_card2_heading')}
                    </Typography>
                    <Typography
                      style={{
                        marginTop: '2rem',
                        fontSize: '14px',
                        fontWeight: 300,
                        fontStyle: 'normal',
                        lineHeight: 'normal',
                        color: 'white',
                        whiteSpace: 'pre-line',
                        fontFamily: 'Axiforma',
                      }}
                    >
                      {t('singularity_calculator_card2_subheading')}
                    </Typography>
                    <Typography
                      style={{
                        marginTop: '1rem',
                        fontSize: '32px',
                        fontWeight: 300,
                        fontStyle: 'normal',
                        lineHeight: 'normal',
                        color: 'white',
                        whiteSpace: 'pre-line',
                        fontFamily: 'Axiforma',
                      }}
                    >
                      + ${formatOurPrice(sum)}
                    </Typography>
                    <Typography
                      style={{
                        marginTop: '.25rem',
                        fontSize: '12px',
                        fontWeight: 300,
                        fontStyle: 'normal',
                        lineHeight: 'normal',
                        letterSpacing: '0.48px',
                        color: 'white',
                        whiteSpace: 'pre-line',
                        fontFamily: 'Axiforma',
                      }}
                    >
                      {t('singularity_calculator_card2_text')}
                    </Typography>
                  </CardContent>
                </Card>
                <Typography
                  style={{
                    marginTop: '2rem',
                    fontSize: '14px',
                    fontWeight: 300,
                    fontStyle: 'normal',
                    lineHeight: 'normal',
                    color: 'white',
                    whiteSpace: 'pre-line',
                    opacity: '0.5',
                    textAlign: 'center',
                    padding: '0 2rem',
                    fontFamily: 'Axiforma',
                  }}
                >
                  {t('singularity_calculator_under_card_text')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </ThemeProvider>
    )
  }

  return (
    <ThemeProvider theme={customTheme}>
      <div
        style={{
          backgroundColor: '#0F0F0F',
          paddingBottom: '6rem',
          fontFamily: 'Axiforma',
        }}
      >
        <Box
          sx={{ display: 'flex', marginTop: '4rem', fontFamily: 'Axiforma' }}
        >
          <Grid container>
            <Grid
              item
              xs={12}
              style={{
                fontFamily: 'Axiforma',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                paddingTop: '10rem',
              }}
            >
              <h2
                style={{
                  marginTop: '0px',
                  marginBottom: '0px',
                  fontFamily: 'Axiforma',
                  fontSize: '48px',
                  fontWeight: '400',
                  fontStyle: 'normal',
                  lineHeight: '60px',
                  textAlign: 'center',
                  color: '#FFF',
                  whiteSpace: 'pre-line',
                }}
              >
                {t('singularity_pricing_calculator_heading')}
              </h2>
              <div
                style={{
                  fontFamily: 'Axiforma',
                  textAlign: 'center',
                  marginTop: '1rem',
                  backgroundColor: '#0076FF',
                  height: '2px',
                  width: '64px',
                }}
              ></div>
              <div
                style={{
                  fontFamily: 'Axiforma',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: '300',
                  lineHeight: '24px',
                  marginTop: '1rem',
                  textAlign: 'center',
                  color: '#AAA',
                  width: '40%',
                }}
              >
                {t('singularity_pricing_calculator_text')}
              </div>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{ display: 'flex', marginTop: '4rem', fontFamily: 'Axiforma' }}
        >
          <Grid
            container
            spacing={3}
            style={{
              height: '100%',
              padding: '0 8rem',
              fontFamily: 'Axiforma',
            }}
          >
            {/* Left side - One big card */}
            <Grid item xs={6}>
              <Card
                style={{
                  fontFamily: 'Axiforma',
                  height: '100%',
                  backgroundColor: '#171717',
                  color: 'white',
                  padding: '2rem',
                  borderRadius: '0px',
                }}
              >
                <CardContent>
                  <Typography
                    style={{
                      fontFamily: 'Axiforma',
                      fontSize: '20px',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      lineHeight: 'normal',
                    }}
                  >
                    {t('singularity_calculator_heading')}
                  </Typography>

                  {/* Slider */}
                  <Slider
                    value={projectLength}
                    onChange={handleSliderChange}
                    valueLabelDisplay="on"
                    valueLabelFormat={(value) => formatValue(value)}
                    marks={[
                      { value: 1, label: '1 month' },
                      { value: 60, label: '60 months' },
                    ]}
                    min={1}
                    max={60}
                    step={1}
                    style={{
                      marginTop: '20px',
                      marginBottom: '20px',
                      fontFamily: 'Axiforma',
                    }}
                  />
                  {/* Two selects */}
                  <Grid container spacing={2} style={{ marginTop: '1rem' }}>
                    {developers.map((developerCategory, categoryIndex) => (
                      <Grid item xs={4} key={categoryIndex}>
                        <Typography
                          style={{
                            fontFamily: 'Axiforma',
                            fontSize: '14px',
                            fontWeight: 300,
                            fontStyle: 'normal',
                            lineHeight: 'normal',
                            marginBottom: '1rem',
                          }}
                        >
                          {categoryIndex === 0
                            ? t('singularity_calculator_select1')
                            : categoryIndex === 1
                            ? t('singularity_calculator_select2')
                            : categoryIndex === 2
                            ? t('singularity_calculator_select3')
                            : categoryIndex === 3
                            ? t('singularity_calculator_select4')
                            : categoryIndex === 4
                            ? t('singularity_calculator_select5')
                            : categoryIndex === 5
                            ? t('singularity_calculator_select6')
                            : ''}
                        </Typography>
                        <div
                          style={{ marginTop: '20px', fontFamily: 'Axiforma' }}
                        >
                          <Button
                            variant="outlined"
                            style={{
                              color: '#AAAAAA',
                              borderColor: 'white',
                              backgroundColor: '#0F0F0F',
                              fontFamily: 'Axiforma',
                            }}
                            onClick={(e) => handleClick(e, categoryIndex)}
                          >
                            {developerCategory.category}
                          </Button>
                          <Popover
                            open={Boolean(anchorEl[categoryIndex])}
                            anchorEl={anchorEl[categoryIndex]}
                            onClose={() => handleClose(categoryIndex)}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'center',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'center',
                            }}
                          >
                            <div
                              style={{
                                padding: '20px',
                                backgroundColor: '#0F0F0F',
                                fontFamily: 'Axiforma',
                              }}
                            >
                              {developerCategory.data.map(
                                (developer, developerIndex) => (
                                  <div
                                    key={developerIndex}
                                    style={{
                                      color: 'white',
                                      fontFamily: 'Axiforma',
                                    }}
                                  >
                                    <Typography
                                      variant="body2"
                                      style={{
                                        color: 'white',
                                        fontFamily: 'Axiforma',
                                      }}
                                    >
                                      {developer.type}
                                    </Typography>
                                    <TextField
                                      type="number"
                                      variant="outlined"
                                      InputLabelProps={{
                                        style: {
                                          color: 'white',
                                          fontFamily: 'Axiforma',
                                        },
                                      }}
                                      InputProps={{
                                        style: textFieldStyles,
                                        classes: {
                                          notchedOutline: classNames({
                                            custom_outline: true,
                                          }),
                                        },
                                      }}
                                      inputProps={{
                                        min: 0,
                                      }}
                                      value={
                                        developer.counter !== null
                                          ? developer.counter
                                          : ''
                                      }
                                      onChange={(e) =>
                                        updateCounter(
                                          categoryIndex,
                                          developerIndex,
                                          e.target.value,
                                        )
                                      }
                                      style={{
                                        marginLeft: '20px',
                                        borderColor: 'white',
                                        fontFamily: 'Axiforma',
                                      }}
                                    />
                                  </div>
                                ),
                              )}
                            </div>
                          </Popover>
                          <div>
                            {developerCategory.data.map(
                              (developer, developerIndex) =>
                                (developer.counter ?? 0) >= 1 ? (
                                  <Typography
                                    key={developerIndex}
                                    variant="body2"
                                    style={{
                                      marginTop: '.5rem',
                                      fontFamily: 'Axiforma',
                                    }}
                                  >
                                    {`${developer.type} (${developer.counter})`}
                                  </Typography>
                                ) : null,
                            )}
                          </div>
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                  <div
                    style={{
                      fontFamily: 'Axiforma',
                      display: 'flex',
                      justifyContent: 'center',
                      marginTop: '2rem',
                    }}
                  >
                    <div
                      style={{
                        fontFamily: 'Axiforma',
                        width: '90%',
                        height: '1px',
                        backgroundColor: '#FFF',
                        opacity: '0.24',
                      }}
                    ></div>
                  </div>
                  <Grid container spacing={2} style={{ marginTop: '1rem' }}>
                    <Grid item xs={12}>
                      <Typography
                        style={{
                          fontFamily: 'Axiforma',
                          fontSize: '20px',
                          fontWeight: 400,
                          fontStyle: 'normal',
                          lineHeight: 'normal',
                          color: 'white',
                        }}
                      >
                        {t('singularity_calculator_footer_heading')}
                      </Typography>
                    </Grid>

                    <Grid container item xs={12} justifyContent="space-between">
                      <Grid item>
                        <Typography
                          style={{
                            fontFamily: 'Axiforma',
                            fontSize: '32px',
                            fontWeight: 300,
                            fontStyle: 'normal',
                            lineHeight: 'normal',
                            color: '#AAA',
                          }}
                        >
                          ${formatSum(sum)}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          style={{
                            fontFamily: 'Axiforma',
                            fontSize: '32px',
                            fontWeight: 300,
                            fontStyle: 'normal',
                            lineHeight: 'normal',
                            color: 'white',
                          }}
                        >
                          ${formatOurPrice(sum)}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid item xs={6}>
                      <Typography
                        style={{
                          fontFamily: 'Axiforma',
                          fontSize: '12px',
                          fontWeight: 300,
                          fontStyle: 'normal',
                          lineHeight: 'normal',
                          color: '#AAA',
                          letterSpacing: '0.48px',
                        }}
                      >
                        {t('singularity_calculator_footer_cost')}
                      </Typography>
                    </Grid>

                    <Grid item xs={6}>
                      <Typography
                        style={{
                          fontFamily: 'Axiforma',
                          fontSize: '12px',
                          fontWeight: 300,
                          fontStyle: 'normal',
                          lineHeight: 'normal',
                          color: 'white',
                          letterSpacing: '0.48px',
                        }}
                      >
                        {t('singularity_calculator_footer_singularity_cost')}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={6} container direction="column">
              <Grid item xs>
                <Card
                  style={{
                    fontFamily: 'Axiforma',
                    height: '60%',
                    padding: '2rem',
                    backgroundColor: '#171717',
                    color: 'white',
                    borderRadius: '0px',
                  }}
                >
                  <CardContent>
                    <Typography
                      style={{
                        fontFamily: 'Axiforma',
                        fontSize: '24px',
                        fontWeight: 600,
                        fontStyle: 'normal',
                        lineHeight: 'normal',
                        color: 'white',
                      }}
                    >
                      {t('singularity_calculator_card1_heading')}
                    </Typography>
                    <div
                      style={{
                        fontFamily: 'Axiforma',
                        textAlign: 'center',
                        marginTop: '1rem',
                        backgroundColor: '#0076FF',
                        height: '2px',
                        width: '32px',
                      }}
                    ></div>
                    <Typography
                      style={{
                        fontFamily: 'Axiforma',
                        marginTop: '1rem',
                        fontSize: '14px',
                        fontWeight: 300,
                        fontStyle: 'normal',
                        lineHeight: 'normal',
                        color: '#AAA',
                        whiteSpace: 'pre-line',
                      }}
                    >
                      {t('singularity_calculator_card1_subheading')}
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: 'Axiforma',
                        marginTop: '1rem',
                        fontSize: '32px',
                        fontWeight: 300,
                        fontStyle: 'normal',
                        lineHeight: 'normal',
                        color: '#AAA',
                        whiteSpace: 'pre-line',
                      }}
                    >
                      + ${formatSum(sum)}
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: 'Axiforma',
                        marginTop: '.25rem',
                        fontSize: '12px',
                        fontWeight: 300,
                        fontStyle: 'normal',
                        lineHeight: 'normal',
                        letterSpacing: '0.48px',
                        color: '#AAA',
                        whiteSpace: 'pre-line',
                      }}
                    >
                      {t('singularity_calculator_card1_text')}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs>
                <Card
                  style={{
                    fontFamily: 'Axiforma',
                    height: '60%',
                    padding: '2rem',
                    backgroundColor: '#0076FF',
                    color: 'white',
                    borderRadius: '0px',
                  }}
                >
                  <CardContent>
                    <Typography
                      style={{
                        fontFamily: 'Axiforma',
                        fontSize: '24px',
                        fontWeight: 600,
                        fontStyle: 'normal',
                        lineHeight: 'normal',
                        color: 'white',
                      }}
                    >
                      {t('singularity_calculator_card2_heading')}
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: 'Axiforma',
                        marginTop: '2rem',
                        fontSize: '14px',
                        fontWeight: 300,
                        fontStyle: 'normal',
                        lineHeight: 'normal',
                        color: 'white',
                        whiteSpace: 'pre-line',
                      }}
                    >
                      {t('singularity_calculator_card2_subheading')}
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: 'Axiforma',
                        marginTop: '1rem',
                        fontSize: '32px',
                        fontWeight: 300,
                        fontStyle: 'normal',
                        lineHeight: 'normal',
                        color: 'white',
                        whiteSpace: 'pre-line',
                      }}
                    >
                      + ${formatOurPrice(sum)}
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: 'Axiforma',
                        marginTop: '.25rem',
                        fontSize: '12px',
                        fontWeight: 300,
                        fontStyle: 'normal',
                        lineHeight: 'normal',
                        letterSpacing: '0.48px',
                        color: 'white',
                        whiteSpace: 'pre-line',
                      }}
                    >
                      {t('singularity_calculator_card2_text')}
                    </Typography>
                  </CardContent>
                </Card>
                <Typography
                  style={{
                    fontFamily: 'Axiforma',
                    marginTop: '2rem',
                    fontSize: '14px',
                    fontWeight: 300,
                    fontStyle: 'normal',
                    lineHeight: 'normal',
                    color: 'white',
                    whiteSpace: 'pre-line',
                    opacity: '0.5',
                  }}
                >
                  {t('singularity_calculator_under_card_text')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </div>
    </ThemeProvider>
  )
}

export default SingularityStartupCalculator
