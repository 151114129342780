import React, { useState, useEffect } from 'react'
import { Button, Typography, useTheme, useMediaQuery } from '@mui/material'
import { useTranslation } from 'react-i18next'

const COOKIE_CONSENT = 'cookie-consent'

const CookieBanner = () => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  useEffect(() => {
    const consentValue = localStorage.getItem(COOKIE_CONSENT)
    if (!consentValue) {
      setOpen(true)
    }
  }, [])

  const handleAgree = () => {
    localStorage.setItem(COOKIE_CONSENT, 'accepted')
    setOpen(false)
  }

  const handleDisagree = () => {
    localStorage.setItem(COOKIE_CONSENT, 'declined')
    setOpen(false)
  }

  if (isMobile) {
    return (
      <>
        {open ? (
          <div
            style={{
              position: 'fixed',
              bottom: 0,
              width: '100%',
              backgroundColor: '#080808',
              color: 'white',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-around',
              height: '140px',
            }}
          >
            <Typography
              style={{
                fontSize: '14px',
                fontWeight: 300,
                fontStyle: 'normal',
                lineHeight: 'normal',
                textAlign: 'center',
                marginBottom: '1rem',
              }}
            >
              {t('singularity_cookie_banner_text')}
            </Typography>
            <div
              style={{ display: 'flex', justifyContent: 'center', gap: '1rem' }}
            >
              <Button
                onClick={handleDisagree}
                type="button"
                style={{
                  padding: '10px 20px',
                  border: '1px solid white',
                  borderRadius: '0',
                  color: 'white',
                }}
              >
                {t('singularity_cookie_banner_decline_button')}
              </Button>
              <Button
                onClick={handleAgree}
                type="button"
                style={{
                  padding: '10px 20px',
                  border: '1px solid #0076FF',
                  backgroundColor: '#0076FF',
                  borderRadius: '0',
                  color: 'white',
                }}
              >
                {t('singularity_cookie_banner_accept_button')}
              </Button>
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </>
    )
  }

  return (
    <>
      {open ? (
        <div
          style={{
            position: 'fixed',
            bottom: 0,
            width: '100%',
            backgroundColor: '#080808',
            color: 'white',
            display: 'flex',
            justifyContent: 'space-around',
            height: '140px',
            alignItems: 'center',
          }}
        >
          <Typography
            style={{
              fontSize: '14px',
              fontWeight: 300,
              fontStyle: 'normal',
              lineHeight: 'normal',
            }}
          >
            {t('singularity_cookie_banner_text')}
          </Typography>
          <div>
            <Button
              onClick={handleDisagree}
              type="button"
              style={{
                padding: '20px',
                border: '1px solid white',
                borderRadius: '0',
                color: 'white',
              }}
            >
              {t('singularity_cookie_banner_decline_button')}
            </Button>
            <Button
              onClick={handleAgree}
              type="button"
              style={{
                padding: '20px',
                border: '1px solid #0076FF',
                backgroundColor: '#0076FF',
                borderRadius: '0',
                color: 'white',
                marginLeft: '2rem',
              }}
            >
              {t('singularity_cookie_banner_accept_button')}
            </Button>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </>
  )
}

export default CookieBanner
