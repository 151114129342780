import React, { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import { Typography, Hidden } from '@mui/material'
import { SingularityProcessData } from './SingularityStartupProcessData'
import { MobileSingularityProcessData } from './MobileSingularityStartupProcessData'
import { useTranslation } from 'react-i18next'
import ProcessDots1 from '../../assets/process_dots_1.png'
import ProcessDots2 from '../../assets/process_dots_2.png'

const SingularityStartupProcessView: React.FC = () => {
  const { t } = useTranslation()
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  if (isMobile) {
    return (
      <>
        <Box sx={{ display: 'flex' }}>
          <Grid container>
            <img
              src={ProcessDots1}
              alt="Dots1"
              style={{
                position: 'absolute',
                width: '25%',
                top: 80,
                left: 0,
                zIndex: -1,
              }}
            />
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                paddingTop: '10rem',
              }}
            >
              <h1
                style={{
                  marginTop: '0px',
                  marginBottom: '0px',
                  fontSize: '48px',
                  fontWeight: '400',
                  fontStyle: 'normal',
                  lineHeight: '60px',
                  textAlign: 'center',
                  color: '#FFF',
                  whiteSpace: 'pre-line',
                }}
              >
                {t('singularity_process_heading')}
              </h1>
            </Grid>
          </Grid>
        </Box>

        <Box
          sx={{
            flexGrow: 1,
            marginTop: '12rem',
            backgroundColor: '#0F0F0F',
            padding: '6rem 3rem',
            justifyContent: 'center',
          }}
        >
          <img
            src={ProcessDots2}
            alt="Dots2"
            style={{
              position: 'absolute',
              width: '40%',
              top: 455,
              right: 10,
              zIndex: 1,
            }}
          />
          {MobileSingularityProcessData.map((data, index) => (
            <Grid
              container
              spacing={3}
              key={index}
              style={{ justifyContent: 'center' }}
            >
              <Grid item xs={12}>
                <Typography
                  style={{
                    fontSize: '36px',
                    fontWeight: '400',
                    fontStyle: 'normal',
                    lineHeight: '40px',
                    color: '#FFF',
                    textAlign: 'center',
                  }}
                >
                  {data.heading}
                </Typography>
                <Typography
                  style={{
                    whiteSpace: 'pre-line',
                    fontSize: '16px',
                    fontWeight: '300',
                    fontStyle: 'normal',
                    lineHeight: '24px',
                    color: '#AAA',
                    marginTop: '1rem',
                    textAlign: 'center',
                  }}
                >
                  {data.text}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <img src={data.image} style={{ width: '25rem' }} />
              </Grid>
            </Grid>
          ))}
        </Box>
      </>
    )
  }

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <Grid container>
          <img
            src={ProcessDots1}
            alt="Dots1"
            style={{ position: 'absolute', top: 120, left: 0, zIndex: -1 }}
          />
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              paddingTop: '10rem',
            }}
          >
            <h1
              style={{
                marginTop: '0px',
                marginBottom: '0px',
                fontSize: '64px',
                fontWeight: '400',
                fontStyle: 'normal',
                lineHeight: '80px',
                textAlign: 'center',
                color: '#FFF',
                whiteSpace: 'pre-line',
              }}
            >
              {t('singularity_process_heading')}
            </h1>
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          flexGrow: 1,
          margin: '16rem 0rem 0 0rem',
          backgroundColor: '#0F0F0F',
          padding: '4rem',
        }}
      >
        <img
          src={ProcessDots2}
          alt="Dots2"
          style={{ position: 'absolute', top: 470, right: 40, zIndex: 1 }}
        />
        {SingularityProcessData.map((data, index) => (
          <Grid
            container
            spacing={3}
            key={index}
            style={{ justifyContent: 'center' }}
          >
            <Grid item xs={0} style={{ display: 'flex' }}>
              <Typography
                style={{
                  marginTop: '6rem',
                  backgroundColor: '#162EFF',
                  color: 'white',
                  width: '40px',
                  height: '40px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  position: 'relative',
                  zIndex: 1,
                }}
              >
                {data.id}
                {index !== 6 ? (
                  <div
                    style={{
                      position: 'absolute',
                      top: '100%',
                      left: '50%',
                      transform: 'translate(-50%, 0%)',
                      width: '2px',
                      height: '24rem',
                      backgroundColor: 'rgba(255, 255, 255, 0.24)',
                      zIndex: -1,
                    }}
                  ></div>
                ) : null}
              </Typography>
            </Grid>

            <Grid item xs={5} style={{ marginTop: '4rem', marginLeft: '2rem' }}>
              <Typography
                style={{
                  fontSize: '48px',
                  fontWeight: '400',
                  fontStyle: 'normal',
                  lineHeight: '60px',
                  color: '#FFF',
                }}
              >
                {data.heading}
              </Typography>
              <div
                style={{
                  textAlign: 'center',
                  marginTop: '1rem',
                  backgroundColor: '#0076FF',
                  height: '2px',
                  width: '64px',
                }}
              ></div>
              <Typography
                style={{
                  whiteSpace: 'pre-line',
                  fontSize: '16px',
                  fontWeight: '300',
                  fontStyle: 'normal',
                  lineHeight: '24px',
                  color: '#AAA',
                  marginTop: '1rem',
                }}
              >
                {data.text}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <img src={data.image} />
            </Grid>
          </Grid>
        ))}
      </Box>
    </>
  )
}

export default SingularityStartupProcessView
