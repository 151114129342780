import React from 'react'
import { useTheme, useMediaQuery } from '@mui/material'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import { SingularityData, SingularityCards } from './SingularityStartupData'
import {
  MobileSingularityData,
  MobileSingularityCards,
} from './MobileSingularityStartupData'
import { useTranslation } from 'react-i18next'

const SingularityStartupDataView: React.FC = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  if (isMobile) {
    return (
      <>
        {MobileSingularityData.map((item, index) => (
          <Box
            key={index}
            sx={{
              padding: '2rem 1rem',
              overflow: 'hidden',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              ...(index === 0 ? { marginTop: '20rem' } : { marginTop: '1rem' }),
              ...(index % 2 === 1
                ? { backgroundColor: '#0F0F0F' }
                : { backgroundColor: '#000' }),
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Grid
                key={index}
                container
                sx={{
                  textAlign: 'start',
                  marginTop: '4rem',
                  padding: '0 1rem',
                  flexDirection: 'row',
                }}
              >
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <h2
                    style={{
                      marginTop: '0px',
                      marginBottom: '0px',
                      fontSize: '32px',
                      fontWeight: '400',
                      fontStyle: 'normal',
                      lineHeight: '110%',
                      color: '#FFF',
                    }}
                  >
                    {item.heading}
                  </h2>
                  <div
                    style={{
                      marginTop: '1rem',
                      backgroundColor: '#0076FF',
                      height: '2px',
                      width: '64px',
                    }}
                  ></div>
                  <div
                    style={{
                      fontSize: '16px',
                      fontStyle: 'normal',
                      fontWeight: '300',
                      lineHeight: '24px',
                      marginTop: '2rem',
                      textAlign: 'start',
                      color: '#AAA',
                      whiteSpace: 'pre-line',
                    }}
                  >
                    {item.text}
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <img
                    src={item.image}
                    alt=""
                    style={{
                      width: '70%',
                      position: 'relative',
                      left: window.innerWidth / 8,
                      height: 'auto',
                    }}
                  />
                </Grid>
              </Grid>
            </div>
          </Box>
        ))}
        <Box sx={{ display: 'flex' }}>
          <Grid container justifyContent="center" spacing={4}>
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                paddingTop: '6rem',
                margin: '0 4rem',
              }}
            >
              <h2
                style={{
                  marginTop: '0px',
                  marginBottom: '0px',
                  fontSize: '36px',
                  fontWeight: '400',
                  fontStyle: 'normal',
                  lineHeight: '110%',
                  textAlign: 'center',
                  color: '#FFF',
                }}
              >
                {t('singularity_startup_footer_heading')}
              </h2>
              <div
                style={{
                  textAlign: 'center',
                  marginTop: '2rem',
                  backgroundColor: '#0076FF',
                  height: '2px',
                  width: '64px',
                }}
              ></div>
            </Grid>

            {MobileSingularityCards.map((data, index) => (
              <Grid
                item
                xs={12}
                md={4}
                lg={3}
                key={data.id}
                sx={{ padding: '0 2rem', marginLeft: '2rem' }}
              >
                <div
                  style={{
                    height: '85%',
                    padding: '2rem',
                    backgroundColor: 'rgba(255, 255, 255, 0.08)',
                  }}
                >
                  <img src={data.image} alt={data.image} />
                  <h3
                    style={{
                      marginTop: '3rem',
                      color: '#FFF',
                      fontSize: '24px',
                      fontStyle: 'normal',
                      fontWeight: 600,
                      lineHeight: 'normal',
                    }}
                  >
                    {data.heading}
                  </h3>
                  <div
                    style={{
                      textAlign: 'center',
                      marginTop: '1rem',
                      backgroundColor: '#0076FF',
                      height: '2px',
                      width: '32px',
                    }}
                  ></div>
                  <div
                    style={{
                      color: '#AAA',
                      marginTop: '1rem',
                      fontSize: '16px',
                      fontStyle: 'normal',
                      fontWeight: 300,
                      lineHeight: '24px',
                      lineBreak: 'auto',
                      maxWidth: '45ch',
                    }}
                  >
                    {data.text}
                  </div>
                </div>
              </Grid>
            ))}
          </Grid>
        </Box>
      </>
    )
  }

  return (
    <>
      {SingularityData.map((item, index) => (
        <Box
          key={index}
          sx={{
            padding: '4rem',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            ...(index === 0 ? { marginTop: '20rem' } : { marginTop: '1rem' }),
            ...(index % 2 === 1
              ? { backgroundColor: '#0F0F0F' }
              : { backgroundColor: '#000' }),
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Grid
              key={index}
              container
              sx={{
                textAlign: 'start',
                marginTop: '4rem',
                padding: '0',
                flexDirection: index % 2 === 0 ? 'row-reverse' : 'row',
              }}
            >
              <Grid
                item
                md={6}
                sx={{
                  marginTop: '5rem',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  padding: '0 4rem',
                }}
              >
                <h2
                  style={{
                    marginTop: '0px',
                    marginBottom: '0px',
                    fontSize: '48px',
                    fontWeight: '400',
                    fontStyle: 'normal',
                    lineHeight: '60px',
                    color: '#FFF',
                    whiteSpace: 'pre-line',
                  }}
                >
                  {item.heading}
                </h2>
                <div
                  style={{
                    marginTop: '1rem',
                    backgroundColor: '#0076FF',
                    height: '2px',
                    width: '64px',
                  }}
                ></div>
                <div
                  style={{
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: '300',
                    lineHeight: '24px',
                    marginTop: '2rem',
                    textAlign: 'start',
                    color: '#AAA',
                    maxWidth: '50ch',
                  }}
                >
                  {item.text}
                </div>
              </Grid>
              <Grid
                item
                md={6}
                style={{
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  padding: '0 4rem',
                }}
              >
                <img src={item.image} alt="" />
              </Grid>
            </Grid>
          </div>
        </Box>
      ))}
      <Box sx={{ display: 'flex' }}>
        <Grid container justifyContent="center" spacing={4}>
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              paddingTop: '8rem',
            }}
          >
            <h2
              style={{
                fontSize: '48px',
                fontWeight: '400',
                fontStyle: 'normal',
                lineHeight: '60px',
                textAlign: 'center',
                color: '#FFF',
                whiteSpace: 'pre-line',
              }}
            >
              {t('singularity_startup_footer_heading')}
            </h2>
            <div
              style={{
                textAlign: 'center',
                marginTop: '2rem',
                backgroundColor: '#0076FF',
                height: '2px',
                width: '64px',
              }}
            ></div>
          </Grid>

          {SingularityCards.map((data, index) => (
            <Grid item xs={12} md={4} lg={3} key={data.id}>
              <div
                style={{
                  height: '85%',
                  padding: '2rem',
                  backgroundColor: 'rgba(255, 255, 255, 0.08)',
                }}
              >
                <img src={data.image} alt={data.image} />
                <div
                  style={{
                    height: '70%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                    marginTop: '1.5rem',
                  }}
                >
                  <h3
                    style={{
                      marginTop: 'auto',
                      color: '#FFF',
                      fontSize: '24px',
                      fontStyle: 'normal',
                      fontWeight: 600,
                      lineHeight: 'normal',
                    }}
                  >
                    {data.heading}
                  </h3>
                  <div
                    style={{
                      textAlign: 'center',
                      marginTop: '1rem',
                      backgroundColor: '#0076FF',
                      height: '2px',
                      width: '32px',
                    }}
                  ></div>
                  <div
                    style={{
                      color: '#AAA',
                      marginTop: '1rem',
                      textAlign: 'start',
                      fontSize: '16px',
                      fontStyle: 'normal',
                      fontWeight: 300,
                      lineHeight: '24px',
                      lineBreak: 'auto',
                      maxWidth: '45ch',
                    }}
                  >
                    {data.text}
                  </div>
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  )
}

export default SingularityStartupDataView
