// TODO: add images colors
import React from 'react'
import { Button, useMediaQuery, useTheme } from '@mui/material'
import Grid from '@mui/material/Grid'
import TransparexLogo from '../../assets/logo-transparex.png'
import GjensidigeLogo from '../../assets/logo-gjensidige.png'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { useNavigate } from 'react-router-dom'
import '../../styles/Home/OurWork.css'
import { useTranslation } from 'react-i18next'

const OurWork: React.FC = () => {
  const router = useNavigate()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const { t } = useTranslation()

  const button_style = {
    padding: '20px 24px',
    textAlign: 'center',
    borderRadius: '0px',
    border: '1px solid #FFF',
    color: '#FFF',
    textTransform: 'initial',
  }

  const handleButtonClick = () => {
    router('/our-work')
  }

  const handleClick = (routerID: string) => {
    router(`/our-work/${routerID}`)
  }

  if (isMobile) {
    return (
      <>
        <Grid container sx={{ padding: '2rem 2rem' }}>
          <Grid
            item
            lg={10}
            md={12}
            sm={12}
            xs={12}
            className={'ourwork_container'}
          >
            <h3 className={'ourwork_heading'}>
              {t('home_screen_our_work_heading')}
            </h3>
            <div className={'ourwork_blue_line'}></div>
            <div className={'ourwork_text'}>
              {t('home_screen_our_work_subheading')}
            </div>
          </Grid>
          <Grid item lg={2} md={4} sm={4} xs={12} className={'ourwork_button'}>
            <Button
              onClick={handleButtonClick}
              variant="outlined"
              sx={button_style}
            >
              {t('home_screen_our_work_button')}{' '}
              <ArrowForwardIcon sx={{ marginLeft: '1rem' }} />
            </Button>
          </Grid>

          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            sx={{ marginTop: '4rem', padding: '0 1.5rem' }}
          >
            <div className={'ourwork_card'}>
              <img src={TransparexLogo} alt="TransparexLogo" />
              <div className={'ourwork_card_heading'}>
                {t('transparex_card_heading')}
              </div>
              <div className={'ourwork_card_text_mobile'}>
                {t('transparex_card_subheading')}
              </div>
              <div
                onClick={() => handleClick('')}
                className={'ourwork_card_button'}
              >
                <ArrowForwardIcon className={'ourwork_card_button_icon'} />
                {t('home_screen_our_work_card1_button')}
              </div>
            </div>
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            sx={{ marginTop: '2rem', padding: '0 1.5rem' }}
          >
            <div className={'ourwork_card2'}>
              <img src={GjensidigeLogo} alt="GjensidigeLogo" />
              <h4 className={'ourwork_card_heading'}>
                {t('home_screen_our_work_card2_heading')}
              </h4>
              <div className={'ourwork_card_text_mobile'}>
                {t('home_screen_our_work_card2_subheading')}
              </div>
              <div
                onClick={() => handleClick('')}
                className={'ourwork_card_button'}
              >
                <ArrowForwardIcon className={'ourwork_card_button_icon'} />
                {t('home_screen_our_work_card2_button')}
              </div>
            </div>
          </Grid>
        </Grid>
      </>
    )
  }

  return (
    <>
      <Grid container sx={{ padding: '4rem 6rem' }}>
        <Grid
          item
          lg={10}
          md={8}
          sm={8}
          xs={12}
          className={'ourwork_container'}
        >
          <h3 className={'ourwork_heading'}>
            {t('home_screen_our_work_heading')}
          </h3>
          <div className={'ourwork_blue_line'}></div>
          <div className={'ourwork_text'}>
            {t('home_screen_our_work_subheading')}
          </div>
        </Grid>
        <Grid item lg={2} md={4} sm={4} xs={12} className={'ourwork_button'}>
          <Button
            onClick={handleButtonClick}
            variant="outlined"
            sx={button_style}
          >
            {t('home_screen_our_work_button')}{' '}
            <ArrowForwardIcon sx={{ marginLeft: '1rem' }} />
          </Button>
        </Grid>

        <Grid item lg={6} md={6} sm={12} xs={12} sx={{ marginTop: '4rem' }}>
          <div className={'ourwork_card'}>
            <div
              style={{ height: '80px', alignItems: 'center', display: 'flex' }}
            >
              <img src={TransparexLogo} alt="TransparexLogo" />
            </div>
            <div className={'ourwork_card_heading'}>
              {t('transparex_card_heading')}
            </div>
            <h4 className={'ourwork_card_heading'}>
              {t('home_screen_our_work_card1_heading')}
            </h4>
            <div className={'ourwork_card_text'}>
              {t('transparex_card_subheading')}
            </div>
            <div
              onClick={() => handleClick('')}
              className={'ourwork_card_button'}
            >
              <ArrowForwardIcon className={'ourwork_card_button_icon'} />
              {t('home_screen_our_work_card1_button')}
            </div>
          </div>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12} sx={{ marginTop: '4rem' }}>
          <div className={'ourwork_card2'}>
            <div
              style={{ height: '80px', alignItems: 'center', display: 'flex' }}
            >
              <img src={GjensidigeLogo} alt="GjensidigeLogo" />
            </div>
            <h4 className={'ourwork_card_heading'}>
              {t('home_screen_our_work_card2_heading')}
            </h4>
            <div className={'ourwork_card_text'}>
              {t('home_screen_our_work_card2_subheading')}
            </div>
            <div
              onClick={() => handleClick('')}
              className={'ourwork_card_button'}
            >
              <ArrowForwardIcon className={'ourwork_card_button_icon'} />
              {t('home_screen_our_work_card2_button')}
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default OurWork
