import React from 'react'
import { Card, CardContent, Button, Typography, Icon } from '@mui/material'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import ArrowForward from '@mui/icons-material/ArrowForward'
import CustomDialog from '../../components/CustomDialog'
import { useTranslation } from 'react-i18next'
import { useTheme, useMediaQuery } from '@mui/material'

const SingularityStartupTiers: React.FC = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const tiers = [
    {
      id: 1,
      heading: 'Free',
      price: '0.00',
      payment_type: 'MONTHLY',
      for_who: 'Small or limited apps',
    },
    {
      id: 2,
      heading: 'Standard',
      price: '99.00',
      payment_type: 'MONTHLY',
      for_who: 'Regular apps',
    },
    {
      id: 3,
      heading: 'Pro',
      price: '199.00',
      payment_type: 'MONTHLY',
      for_who: 'Big or state-of-the-art apps',
    },
  ]
  const [open, setOpen] = React.useState(false)

  if (isMobile) {
    return (
      <>
        <Box sx={{ display: 'flex', marginTop: '4rem' }}>
          <Grid container>
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                paddingTop: '2rem',
                padding: '0 2rem',
              }}
            >
              <h1
                style={{
                  marginTop: '0px',
                  marginBottom: '0px',
                  fontSize: '40px',
                  fontWeight: '400',
                  fontStyle: 'normal',
                  lineHeight: '130%',
                  textAlign: 'center',
                  color: '#FFF',
                  whiteSpace: 'pre-line',
                }}
              >
                {t('singularity_pricing_heading')}
              </h1>
              <div
                style={{
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: '300',
                  lineHeight: '24px',
                  marginTop: '1rem',
                  textAlign: 'center',
                  color: '#AAA',
                  whiteSpace: 'pre-line',
                }}
              >
                {t('singularity_pricing_subheading')}
              </div>
            </Grid>
          </Grid>
        </Box>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            marginTop: '10rem',
          }}
        >
          {tiers.map((tier, index) => (
            <Card
              key={index}
              style={{
                minWidth: '300px',
                maxWidth: '380px',
                flex: 1,
                margin: '20px',
                backgroundColor: '#0076FF',
                color: 'white',
                padding: '1rem',
              }}
            >
              <CardContent>
                {/* Top row with text on left and right */}
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography
                    style={{
                      fontSize: '24px',
                      fontStyle: 'normal',
                      fontWeight: '600',
                      lineHeight: 'normal',
                    }}
                    component="div"
                  >
                    {tier.heading}
                  </Typography>
                  <Typography
                    style={{
                      fontSize: '32px',
                      fontStyle: 'normal',
                      fontWeight: '300',
                      lineHeight: 'normal',
                    }}
                    component="div"
                  >
                    ${tier.price}
                  </Typography>
                </Box>

                {/* Text only on the right */}
                <Box display="flex" justifyContent="flex-end">
                  <Typography
                    style={{
                      fontSize: '12px',
                      fontStyle: 'normal',
                      fontWeight: '300',
                      lineHeight: 'normal',
                      letterSpacing: '0.48px',
                    }}
                  >
                    {tier.payment_type}
                  </Typography>
                </Box>

                {/* Full-width text */}
                <Typography
                  mt={2}
                  style={{
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: '300',
                    lineHeight: 'normal',
                    width: '100%',
                  }}
                >
                  {tier.for_who}
                </Typography>

                {/* Button */}
                <Box mt={2}>
                  <Button
                    onClick={() => setOpen(true)}
                    variant="outlined"
                    style={{
                      color: 'white',
                      border: '1px solid white',
                      borderRadius: '0px',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      padding: '1rem 2rem',
                    }}
                    fullWidth
                  >
                    Subscribe
                    <ArrowForward />
                  </Button>
                </Box>
              </CardContent>
            </Card>
          ))}
        </div>
        <CustomDialog open={open} onClose={() => setOpen(false)} />
      </>
    )
  }

  return (
    <>
      <Box sx={{ display: 'flex', marginTop: '4rem' }}>
        <Grid container>
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              paddingTop: '10rem',
            }}
          >
            <h1
              style={{
                marginTop: '0px',
                marginBottom: '0px',
                fontSize: '64px',
                fontWeight: '400',
                fontStyle: 'normal',
                lineHeight: '80px',
                textAlign: 'center',
                color: '#FFF',
                whiteSpace: 'pre-line',
              }}
            >
              {t('singularity_pricing_heading')}
            </h1>
            <div
              style={{
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: '300',
                lineHeight: '24px',
                marginTop: '1rem',
                textAlign: 'center',
                color: '#AAA',
                whiteSpace: 'pre-line',
              }}
            >
              {t('singularity_pricing_subheading')}
            </div>
          </Grid>
        </Grid>
      </Box>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          marginTop: '10rem',
          paddingLeft: '12rem',
          paddingRight: '12rem',
        }}
      >
        {tiers.map((tier, index) => (
          <Card
            key={index}
            style={{
              minWidth: '300px',
              maxWidth: '380px',
              flex: 1,
              margin: index !== tiers.length ? '20px 20px 20px 0' : '20px',
              backgroundColor: '#0076FF',
              color: 'white',
              padding: '1rem',
            }}
          >
            <CardContent>
              {/* Top row with text on left and right */}
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography
                  style={{
                    fontSize: '24px',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    lineHeight: 'normal',
                  }}
                  component="div"
                >
                  {tier.heading}
                </Typography>
                <Typography
                  style={{
                    fontSize: '32px',
                    fontStyle: 'normal',
                    fontWeight: '300',
                    lineHeight: 'normal',
                  }}
                  component="div"
                >
                  ${tier.price}
                </Typography>
              </Box>

              {/* Text only on the right */}
              <Box display="flex" justifyContent="flex-end">
                <Typography
                  style={{
                    fontSize: '12px',
                    fontStyle: 'normal',
                    fontWeight: '300',
                    lineHeight: 'normal',
                    letterSpacing: '0.48px',
                  }}
                >
                  {tier.payment_type}
                </Typography>
              </Box>

              {/* Full-width text */}
              <Typography
                mt={2}
                style={{
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '300',
                  lineHeight: 'normal',
                  width: '100%',
                }}
              >
                {tier.for_who}
              </Typography>

              {/* Button */}
              <Box mt={2}>
                <Button
                  onClick={() => setOpen(true)}
                  variant="outlined"
                  style={{
                    color: 'white',
                    border: '1px solid white',
                    borderRadius: '0px',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '1rem 2rem',
                  }}
                  fullWidth
                >
                  Subscribe
                  <ArrowForward />
                </Button>
              </Box>
            </CardContent>
          </Card>
        ))}
      </div>
      <CustomDialog open={open} onClose={() => setOpen(false)} />
    </>
  )
}

export default SingularityStartupTiers
