import React from 'react'
import { Button } from '@mui/material'
import Grid from '@mui/material/Grid'
import BlueLight from '../../assets/home_blue_light.webp'
import { useNavigate } from 'react-router-dom'
import '../../styles/Home/About.css'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { useTranslation } from 'react-i18next'

const About: React.FC = () => {
  const router = useNavigate()
  const { t } = useTranslation()
  const handleButtonClick = () => {
    router('/about')
  }

  const button_style = {
    padding: '20px 24px',
    textAlign: 'center',
    marginTop: '2rem',
    borderRadius: '0px',
    border: '1px solid #FFF',
    color: '#FFF',
    textTransform: 'initial',
  }

  return (
    <>
      <Grid container className={'about_container'}>
        <Grid item xs={12} className={'about_grid'}>
          <img
            src={BlueLight}
            alt="background-light"
            style={{
              position: 'absolute',
              top: -250,
              left: 0,
              width: '100%',
              height: '150%',
              zIndex: -2,
            }}
          />
          <div className={'about_subheading'}>
            {t('home_screen_about_subheading')}
          </div>
          <h3 className={'about_heading'}>
            {t('home_screen_about_heading')}
          </h3>
          <div className={'about_divider'}></div>
          <div className={'about_text'}>{t('home_screen_about_text')}</div>
          <div className={'about_button_div'}>
            <Button
              onClick={handleButtonClick}
              variant="outlined"
              sx={button_style}
            >
              {t('home_screen_about_button')}{' '}
              <ArrowForwardIcon sx={{ marginLeft: '1rem' }} />
            </Button>
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default About
